import { inject, InjectionKey, provide } from 'vue';
import { Emitter } from 'mitt';

export type OmniSearchContext = {
  on: Emitter['on'];
  off: Emitter['off'];
  emit: Emitter['emit'];
};

const OmniSearchContextSymbol: InjectionKey<OmniSearchContext> = Symbol('OmniSearch');

export function useProvideOmniSearchContext({
  childEmitter,
  parentEmitter,
}: {
  childEmitter: Emitter;
  parentEmitter: Emitter;
}) {
  provide(OmniSearchContextSymbol, {
    on: parentEmitter.on,
    off: parentEmitter.off,
    emit: childEmitter.emit,
  });
}

export function useConsumeOmniSearchContext() {
  return inject(OmniSearchContextSymbol) as OmniSearchContext;
}
