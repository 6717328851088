<template>
  <a
    class="stretched-link btn btn-secondary rounded-circle back-to-top px-0"
    href="#"
    @click.prevent="$emit('close-toast')"
  >
    <BaseIcon name="arrow_upward" />
  </a>
</template>

<style lang="scss" scoped>
.back-to-top {
  width: 32px;
  height: 32px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

  // avoid depending on custom branding styles via btn-secondary (they are not
  // present in the tree that toasts are placed in)
  color: $gray;
  border-color: $gray;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseIcon from '@productiv/compass/components/BaseIcon.vue';

export default defineComponent({
  name: 'BackToTopButton',
  components: {
    BaseIcon,
  },
  emits: ['close-toast'],
});
</script>
