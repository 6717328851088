<template>
  <div
    id="splash-screen"
    class="vh-100 container-fluid d-flex flex-column justify-content-center align-items-center"
  >
    <div class="card mb-4 w-35 p-5 h-fit">
      <h3 class="mb-3">
        {{ appStoreUserMessageTitle }}
      </h3>
      <p class="mb-0">
        {{ appStoreUserMessage }}
      </p>
    </div>
    <div class="card productiv-admin-card bg-info mb-4 w-35 p-3 h-fit">
      <h3>Productiv admins</h3>
      <p class="mb-0">
        {{ productivAdminsMessage }}
      </p>
    </div>
    <div class="w-35">
      <img class="pl-1" src="@/assets/PoweredbyProductiv.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#splash-screen {
  min-height: 100%;
  background-color: palette-color('blue', 'lighter');
}
.card {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.32);
}
</style>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import useAppCenterStatusModule from '@/store/useAppCenterStatusModule';
import {
  APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_TITLE_MAPPING,
  APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_MAPPING,
  APP_CENTER_STATUS_REASON_TO_ADMIN_MESSAGE_MAPPING,
} from '@/constants/appCenterStatus';

export default defineComponent({
  name: 'AppCenterNotAccessible',
  setup() {
    const { appCenterNotEnabledReason } = useAppCenterStatusModule();

    const productivAdminsMessage = computed(() => {
      return APP_CENTER_STATUS_REASON_TO_ADMIN_MESSAGE_MAPPING[appCenterNotEnabledReason.value!];
    });
    const appStoreUserMessageTitle = computed(() => {
      return APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_TITLE_MAPPING[
        appCenterNotEnabledReason.value!
      ];
    });
    const appStoreUserMessage = computed(() => {
      return APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_MAPPING[appCenterNotEnabledReason.value!];
    });
    return {
      appCenterNotEnabledReason,
      appStoreUserMessageTitle,
      appStoreUserMessage,
      productivAdminsMessage,
    };
  },
});
</script>
