import { computed, Ref } from 'vue';
import { Store, useStore } from 'vuex';
import USER_PERMISSION from './userPermissions';

export default function usePermissions(
  userPermissions: USER_PERMISSION[],
  passedInStore?: Store<unknown>,
): Ref<boolean> {
  const store = passedInStore || useStore();
  const allPermissions = computed(() => store.getters['auth/permissions']);
  const hasPermission = computed(() =>
    userPermissions.every((permission: USER_PERMISSION) =>
      allPermissions.value.includes(permission),
    ),
  );
  return hasPermission;
}

export function useDashboardAccess() {
  const store = useStore();

  const canAccessDashboard = computed(() => {
    const permissions = store.getters['auth/permissions'];
    return permissions.includes(USER_PERMISSION.DASHBOARD_ACCESS);
  });

  const hasResourceAccess = computed(() => {
    const resources = store.getters['auth/resourcePerms'];
    return resources.length > 0;
  });

  return {
    canAccessDashboard,
    hasResourceAccess,
  };
}
