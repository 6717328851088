import { Router, RouterLink } from 'vue-router';
import { Store } from 'vuex';
import { App, h, unref } from 'vue';
import usePermissions from '../login/usePermissions';
import USER_PERMISSION from '../login/userPermissions';
import { APP_CENTER_STATUS_REASONS } from '../constants/appCenterStatus';
import { createSubmoduleMountPoint, resolveCrossLink } from './asyncLoader';
import { useQuery } from './useQuery';
import notFoundRoute from './notFoundRoute';
import { tagFeatureTeam } from './routeUtils';
import { REQUESTS_ROUTE_NAMES } from '@/requests/constants';
import requestsBundle from '@/requests';
import AppCenterNotAccessible from '@/views/AppCenterNotAccessible.vue';
import Home from '@/views/Home.vue';
import { authenticatedRoute } from '@/login';
import { CATALOG_ROUTE_NAMES } from '@/catalog/constants';
import { PROCUREMENT_ROUTE_NAMES } from '@/procurementCentral/constants';

import { APPROVALS_ROUTE_NAMES } from '@/approvals/constants';
import { useAsyncSetup } from '@/utils/useAsyncSetup';
import catalogBundle from '@/catalog';
import approvalsBundle from '@/approvals';
import procurementBundle from '@/procurementCentral';

import FAQPage from '@/faqs/FAQPage.vue';
import FAQLayout from '@/faqs/FAQLayout.vue';
import useAppCenterStatusModule from '@/store/useAppCenterStatusModule';

export function getApplicationRoutes(app: App<any>, store: Store<any>, router: Router) {
  return [
    authenticatedRoute({
      path: '/',
      name: 'home',
      component: useAsyncSetup(async () => {
        const demoMode = unref(useQuery<boolean>('demoMode'));
        const customerId = unref(useQuery<string>('verifyCustomer')); // FIXME(@alexv): cannot be a permanent solution!!!
        // FIXME(@alexv): need to do permissions check
        if (demoMode) {
          store.commit('api/setCustomerOverride', { customerId: 'productivdemo-com' });
        } else if (customerId) {
          store.commit('api/setCustomerOverride', { customerId });
        }

        const { isAppCenterEnabled, loadAppCenterStatus, appCenterNotEnabledReason } =
          useAppCenterStatusModule();
        await loadAppCenterStatus();

        const isAppCenterFeatureNotEnabledForCustomer =
          unref(appCenterNotEnabledReason) ===
          APP_CENTER_STATUS_REASONS['APP_CENTER_NOT_ENABLED_FOR_CUSTOMER'];

        const isAppStoreAdmin = usePermissions([USER_PERMISSION.APPSTORE_ADMIN_READ], store);

        if (
          (!unref(isAppCenterEnabled) && !isAppStoreAdmin.value) ||
          isAppCenterFeatureNotEnabledForCustomer
        ) {
          router.replace({ name: 'no-access' });
        } else {
          if (router.currentRoute.value.name === 'no-access') {
            router.replace({ name: 'home' });
          }
        }

        return Promise.resolve();
      })(Home),
      redirect(to) {
        return resolveCrossLink({
          replace: true,
          name: CATALOG_ROUTE_NAMES.REDIRECT,
          query: to.query,
        });
      },
      children: [
        {
          path: '/no-access',
          name: 'no-access',
          component: AppCenterNotAccessible,
        },
        tagFeatureTeam(
          {
            path: `/faq`,
            name: 'faq',
            components: {
              layout: FAQLayout,
              content: FAQPage,
              header: catalogBundle.importComponent('AppHeader'),
            },
          },
          'AppCenter',
        ),
        createSubmoduleMountPoint('procurement', {
          app,
          providedRouteNames: Object.values(PROCUREMENT_ROUTE_NAMES),
          asyncModuleFactory: () => procurementBundle.importModule('procurementPlugin'),
          pluginOptions: { store, router },
          featureTeam: 'ITOps',
        }),
        createSubmoduleMountPoint('catalog', {
          app,
          providedRouteNames: Object.values(CATALOG_ROUTE_NAMES),
          asyncModuleFactory: () => catalogBundle.importModule('catalogPlugin'),
          pluginOptions: { store, router },
          featureTeam: 'AppCenter',
        }),
        createSubmoduleMountPoint('approvals', {
          app,
          providedRouteNames: Object.values(APPROVALS_ROUTE_NAMES),
          asyncModuleFactory: () => approvalsBundle.importModule('approvalsPlugin'),
          pluginOptions: { store, router },
          featureTeam: 'AppCenter',
        }),
        createSubmoduleMountPoint('requests', {
          app,
          providedRouteNames: Object.values(REQUESTS_ROUTE_NAMES),
          asyncModuleFactory: () => requestsBundle.importModule('requestsPlugin'),
          pluginOptions: { router },
          featureTeam: 'ITOps',
        }),
        notFoundRoute,
      ],
    }),
  ];
}

export default function installApplicationRoutes(
  app: App,
  { store, router }: { store: Store<any>; router: Router },
) {
  app.component('CrossLink', (props, context) =>
    h(
      RouterLink,
      { ...context.attrs, to: resolveCrossLink(context.attrs.to as any) },
      context.slots,
    ),
  );
  getApplicationRoutes(app, store, router).forEach((route) => router.addRoute(route));
}
