import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { Router } from 'vue-router';
import { App } from 'vue';
import beforeSend from './beforeSend';
import applyFeatureTeamTag from './applyFeatureTeamTag';
import * as Environment from '@/utils/environment';

export default function initSentry(app: App, router: Router) {
  if (Environment.IS_SENTRY_ENABLED) {
    Sentry.init({
      app,
      attachProps: true,
      logErrors: true,
      normalizeDepth: 7,
      dsn: Environment.SENTRY_DSN,
      autoSessionTracking: true,
      ignoreErrors: [
        // NOTE(@alexv): justification: Network Error could mean anything from CORS error to not connected to internet. No
        // other information is given to the code, so these don't do a lot of good.
        'Network Error',
        // NOTE(@alexv): justification: Similar to Network Error but raised by axios
        'timeout exceeded',
        // NOTE(@alexv): justification: User has aborted request by pressing esc or leaving the page. Nothing useful about this error.
        'Request aborted',
      ],
      environment: Environment.ENVIRONMENT_NAME,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router) as any,
          tracingOrigins: [/(api|appcenter)\..*?productiv\.com/],
          idleTimeout: 10000,
        }) as any,
      ],
      beforeSend,
      // NOTE(@alexv): AppCenter is pretty low volume for now, lets see if 40% sample rate
      // is ok. we run our automated tests on it but not as often or with as much rigor as
      // the prober. we may want to exclude the prober or downsample its results so we can
      // collect more data from real customers.
      tracesSampleRate: Environment.BRANCH_NAME ? 1.0 : 0.4,
      // ignore errors from external scripts
      denyUrls: [/^https:\/\/(js|deploy)\.userpilot\.io/],
    });

    // NOTE(@alexv): set variables injected by the build at the top-level,
    // so that they are attached to all events emitted afterwards
    if (Environment.BRANCH_NAME) {
      Sentry.setTag('branch', Environment.BRANCH_NAME);
    }
    if (Environment.BRANCH_OWNER) {
      Sentry.setTag('branch_owner', Environment.BRANCH_OWNER);
    }

    applyFeatureTeamTag(router);
  }
}
