import axios from 'axios';
import qs from 'qs';
import lodashSome from 'lodash/some';
import { fromUint8Array } from 'js-base64';
import { AUTH_PROVIDER, EXPECTED_LOGIN_ERRORS } from '../constants';
import { UserInfo } from '../jwt';
import * as Environment from '@/utils/environment';

export class UnreportedLoginError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export interface CallbackParameters {
  error_description?: string;
  code?: string;
}

export function bufferToUrlSafeString(buffer: ArrayBuffer) {
  return fromUint8Array(new Uint8Array(buffer))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export default class AuthClientBase {
  private window: Window & typeof globalThis;
  private baseConfig: AuthClientBaseClientConfig;
  protected redirectUriSignIn: string;
  protected redirectUriSignOut: string;
  public source: AUTH_PROVIDER;

  public constructor(source: AUTH_PROVIDER, { clientId }: AuthClientBaseClientConfig) {
    this.source = source;
    this.window = window;
    this.baseConfig = { clientId };
    this.redirectUriSignIn = this.getBaseUrl() + 'callback/';
    this.redirectUriSignOut = this.getBaseUrl();
  }

  launchUrl(url: string) {
    return this.window.location.replace(url);
  }

  /**
   * Returns the protocol + host + port for the current app
   */
  getBaseUrl() {
    return `${this.window.location.protocol}//${this.window.location.host}/`;
  }

  /**
   * Returns Auth Service APIs URL
   * @returns {string}
   */
  get authServiceUrl() {
    return `${Environment.API_BASE_URL}auth/`;
  }

  async generateS256CodeChallengeAndVerifier() {
    const randomBytes = crypto.getRandomValues(new Uint8Array(32));
    const codeVerifier = bufferToUrlSafeString(randomBytes);
    const codeChallenge = await crypto.subtle.digest(
      'SHA-256',
      // digest pseudo-base64 string of random bytes, NOT random bytes themselves
      new TextEncoder().encode(codeVerifier),
    );

    return {
      codeVerifier,
      codeChallenge: bufferToUrlSafeString(codeChallenge),
    };
  }

  /**
   * Redirects to the login URL for the auth client.
   */
  async initAuth({
    state,
    identityProvider,
    stagingLoginEmail,
  }: {
    state: string;
    identityProvider: string | null;
    stagingLoginEmail: string | null;
  }) {
    const { codeVerifier, codeChallenge } = await this.generateS256CodeChallengeAndVerifier();
    const authorizeUrl = this.getAuthUrl({
      identityProvider,
      state,
      stagingLoginEmail,
      codeChallenge,
    });
    return { codeVerifier, authorizeUrl };
  }

  /**
   * Returns authorize url
   */
  getAuthUrl({
    state,
    identityProvider,
    stagingLoginEmail,
    codeChallenge,
  }: {
    state: string;
    identityProvider: string | null;
    stagingLoginEmail: string | null;
    codeChallenge: string;
  }) {
    const url = `${this.authServiceUrl}oauth2/authorize?`;
    const query = qs.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response_type: 'code',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: this.baseConfig.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: this.redirectUriSignIn,
      state: state,
      scope: this.scope,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      login_hint: stagingLoginEmail,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      identity_provider: identityProvider,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      code_challenge: codeChallenge,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      code_challenge_method: 'S256',
    });

    return url + query;
  }

  /**
   * Populates authData from the login callback.
   */
  async logIn({ query, codeVerifier }: { query: CallbackParameters; codeVerifier: string | null }) {
    if (!query.code) {
      if (query.error_description) {
        throw this.getErrorFromDescription(query.error_description);
      }
      throw new Error('No code or error description found in login callback');
    }
    const {
      refreshToken,
      userInfo,
    }: {
      refreshToken: string;
      userInfo: UserInfo;
    } = await this.fetchTokenFromAuthCode(query.code, codeVerifier);

    return {
      refreshToken,
      userInfo,
    };
  }

  /**
   * Refresh the current auth tokens.
   */
  async refreshTokens({ refreshToken }: { refreshToken: string }) {
    const { userInfo }: { userInfo: UserInfo } =
      await this.fetchTokenFromRefreshToken(refreshToken);

    return {
      userInfo,
    };
  }

  /**
   * Allows logged in support users to assume role
   */
  async assumeRole({ targetCid, targetUser }: { targetCid: string; targetUser: string }) {
    const url = `${this.authServiceUrl}assume-role?`;
    const query = qs.stringify({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      target_cid: targetCid,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      target_user: targetUser,
    });
    const { userInfo } = await this.executePostRequest(url + query, {});
    return { userInfo };
  }

  /**
   * Clear tokens and redirects to logout URL.
   */
  async logOut({ refreshToken }: { refreshToken: string }) {
    const signOutUrl = await this.getSignOutUrl({ refreshToken });
    this.launchUrl(signOutUrl);
  }

  /**
   * Returns the config object that can be used to initialize the client in a new browser window
   */
  getClientConfig(): object {
    return this.baseConfig;
  }

  get scope(): string {
    // auth0 requires scope 'offline_access' to enable refresh token flow
    if (this.source === AUTH_PROVIDER.AUTH0) {
      return 'openid email profile offline_access';
    }
    return 'openid email profile';
  }

  protected async getSignOutUrl({ refreshToken }: { refreshToken: string }) {
    const url = `${this.authServiceUrl}logout`;
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: this.baseConfig.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      logout_uri: this.redirectUriSignOut,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: refreshToken,
    };

    const signoutData = await this.executePostRequest(url, data);
    return signoutData.signoutUrl;
  }

  protected fetchTokenFromAuthCode(code: string, codeVerifier: string | null) {
    const url = `${this.authServiceUrl}oauth2/token`;
    const data: {
      grant_type: string;
      client_id: string;
      redirect_uri: string;
      code: string;
      code_verifier?: string;
    } = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: 'authorization_code',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: this.baseConfig.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: this.redirectUriSignIn,
      code,
    };
    if (codeVerifier) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      data.code_verifier = codeVerifier;
    }

    return this.executePostRequest(url, data);
  }

  protected fetchTokenFromRefreshToken(refreshToken: string) {
    const url = `${this.authServiceUrl}oauth2/token`;
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: 'refresh_token',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: this.baseConfig.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: this.redirectUriSignIn,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: refreshToken,
    };

    return this.executePostRequest(url, data);
  }

  async executePostRequest(url: string, data: string | object = {}) {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      return (
        await axios({
          method: 'post',
          data,
          withCredentials: true,
          url,
          headers,
        })
      ).data;
    } catch (e) {
      const err = e as any;
      throw this.getErrorFromDescription(err.response?.data?.error ?? err.message ?? err);
    }
  }

  getErrorFromDescription(message: string) {
    // AWS prefixes "<LambdaName> failed with error" to the error message of the error object thrown in the lambdas.
    // Eg. "PreSignUp failed with error Unable to capture email from SAML."
    const messageCleaned = message
      .replace(/.* failed with error/g, '')
      .replace(/\(Service: .*\)/g, '')
      .trim();
    const error = new Error(messageCleaned);
    if (
      lodashSome(EXPECTED_LOGIN_ERRORS, (expectedMsg: string) =>
        error.message.includes(expectedMsg),
      )
    ) {
      // Do not report error to Sentry if user tries to login with an invalid/blocked email.
      return new UnreportedLoginError(error.message);
    }
    return error;
  }
}
