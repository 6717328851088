import { computed, ComputedRef, unref } from 'vue';
import { colors } from '@productiv/compass';
import * as Sentry from '@sentry/browser';
import { defaultsDeep } from 'lodash';
import useAppCenterStatusModule, { AppCenterPalette } from '@/store/useAppCenterStatusModule';

const brandDefaultValues = {
  primaryTheme: {
    defaultColor: colors['default-brand'],
    hoverColor: colors['dark-brand'],
    pressedColor: colors['default-blue'],
  },
  textColor: colors['white'],
  linkTheme: colors['default-brand'],
};

export default function useWhiteLabelingThemes() {
  const { appCenterTheme } = useAppCenterStatusModule();

  const theme: ComputedRef<AppCenterPalette> = computed(() =>
    defaultsDeep({}, unref(appCenterTheme)?.theme, brandDefaultValues),
  );

  if (!unref(appCenterTheme)) {
    Sentry.captureException(new Error('missing app center theme'));
  }

  return {
    primaryColor: computed(() => {
      return {
        defaultColor: unref(theme).primaryTheme.defaultColor,
        hoverColor: unref(theme).primaryTheme.hoverColor,
        pressedColor: unref(theme).primaryTheme.pressedColor,
      };
    }),
    linkColor: computed(() => unref(theme).linkTheme),
    textColor: computed(() => unref(theme).textColor),
    logoUrl: computed(() => unref(appCenterTheme)?.logo?.url || null),
    productivHomeBannerTitle: computed(
      () => unref(appCenterTheme)?.titles?.homeBannerTitle || 'Welcome to Productiv',
    ),
    productivHomeBannerSubTitle: computed(
      () =>
        unref(appCenterTheme)?.titles?.homeBannerSubTitle ||
        'The place to request everything you need to get your job done.',
    ),
    requestAccessBannerTitle: computed(
      () =>
        unref(appCenterTheme)?.titles?.requestAccessBannerTitle ||
        'Find and get tools to get things done.',
    ),
  };
}
