import { AxiosInstance, AxiosResponse } from 'axios';
import { computed, reactive } from 'vue';
import { Module, Store } from 'vuex';
import { Status } from '@/utils/watchAsyncEffect';

interface LoaderModuleState<TLoadedData> {
  loaded: boolean;
  loading: boolean;
  error: Error | null;
  loadedData: TLoadedData | null;
}

/**
 * Creates a Status object for use in a StatusIndicator given a loaderModule state and dispatch function.
 * The state and dispatch function should be pre-scoped to the module (eg, by useStoreModule).
 * @returns {Status} A reactive status object
 */
export function getStatus(
  state: LoaderModuleState<any>,
  dispatch: (actionName: string) => Promise<any>,
): Status {
  return reactive({
    loading: computed(() => state.loading),
    errors: computed(() => (state.error ? { error: state.error } : undefined)),
    retry: () => dispatch('load'),
  });
}

/**
 * Creates a store module which can be used to load a single piece of data with loading status. This
 * module is ideal for cases such as:
 *  - sharing a single, non-parameterized piece of data from an API across multiple components
 *  - starting a load high in the component tree and consuming the result across many children
 *  - usage as a submodule inside other store modules
 *
 * It is not typically suited for cases where you need to parameterize an API call based on router parameters,
 * or when you need to load and then consume data in the same component without a need to share data more broadly.
 * For cases such as those, you may want to consider using `useApiCall` instead.
 * @param loader Function to load the data. It will be passed the authenticatedAxiosInstance as its argument
 * @returns Store module suitable for installing into a store.
 */
export default function createLoaderModule<TLoadedData>(
  loader: (axios: AxiosInstance, store: Store<any>) => Promise<AxiosResponse<TLoadedData | null>>,
): Module<LoaderModuleState<TLoadedData>, any> {
  return {
    namespaced: true,
    state: () => ({
      loaded: false,
      loading: false,
      error: null,
      loadedData: null,
    }),
    mutations: {
      startLoading(state) {
        state.loading = true;
        state.error = null;
      },
      failedLoading(state, { error }) {
        state.loading = false;
        state.loaded = false;
        state.error = error;
      },
      finishLoading(state, { data }) {
        state.loadedData = data;
        state.loading = false;
        state.loaded = true;
      },
    },
    getters: {
      requiresLoad(state) {
        return !state.loaded && !state.loading;
      },
      data(state) {
        return state.loadedData;
      },
    },
    actions: {
      async load({ commit, rootGetters }) {
        commit('startLoading');
        try {
          const response = await loader(rootGetters['api/authenticatedAxiosInstance'], this);
          commit('finishLoading', { data: response.data });
        } catch (e) {
          commit('failedLoading', e);
          throw e;
        }
      },
      async loadOnce({ dispatch, getters, state }) {
        if (!getters.requiresLoad) {
          if (!state.loading) {
            return Promise.resolve();
          }

          // NOTE(@alexv): if you dispatch this action while another invocation has put
          // the module into a pending state, it will terminate immediately, which isn't
          // really the intuitive behavior - usually, people expect to be able to use this
          // as a guard to ensure the data is present. We can support that functionality
          // by watching for the loading state to become false and resolving a promise when
          // that state changes.
          return new Promise<void>((resolve, reject) => {
            const unwatch = this.watch(
              () => state.loading,
              (isLoading) => {
                if (isLoading) {
                  return;
                }
                unwatch();
                if (state.error) {
                  reject(state.error);
                } else {
                  resolve();
                }
              },
            );
          });
        }
        await dispatch('load');
      },
    },
  };
}
