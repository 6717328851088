<template>
  <Teleport v-for="(_, id) in $slots" :key="id" :to="`#${id}`">
    <slot :name="id" />
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BasePageContent',
});
</script>
