export const APPROVALS_MODULE_NAME = 'approvals';

export enum APPROVALS_ROUTE_NAMES {
  APPROVED = 'approvals-approved',
  DECLINED = 'approvals-declined',
  INVALID = 'approvals-invalid',
  RESPOND = 'approvals-respond',
}

export enum APPROVAL_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum APPROVAL_MEDIUM {
  SLACK = 'SLACK',
  EMAIL = 'EMAIL',
}
