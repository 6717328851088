// permissions documented at https://docs.google.com/document/d/1qAKBO2g1JqtygmpBJFiY2IAXKvoxHtxdrtkE1U4m9h8/edit#
enum USER_PERMISSION {
  DASHBOARD_ACCESS = 'ituser',
  WRITE_USER_SETTINGS = 's|usr|w',
  APPSTORE_ADMIN_READ = 'as|all|r',
  APPSTORE_ADMIN_WRITE = 'as|all|w',
  APPSTORE_SELF_WRITE = 'as|self|w',

  //super admin permission
  WORKFLOW_WRITE = 's|wf|r',
}
export default USER_PERMISSION;
