<template>
  <div class="w-100 p-5 m-5 d-flex justify-content-center lead">
    Reverting Role
    <div class="mx-2">
      <StatusIndicator loading />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import * as Environment from '@/utils/environment';

export default defineComponent({
  name: 'RevertRole',
  components: {
    StatusIndicator,
  },
  computed: {
    ...mapGetters({
      isAssumedRole: 'auth/isAssumedRole',
    }),
  },
  async mounted() {
    if (!this.isAssumedRole) {
      this.$router.replace({
        name: 'home',
      });
    }
    try {
      await this.revertRole();

      // hard refresh to home page
      window.location.replace(Environment.BASE_URL);
    } catch (err) {
      this.$router.replace({
        name: 'user-logout',
      });
    }
  },
  methods: {
    ...mapActions({ revertRole: 'auth/revertRole' }),
  },
});
</script>
