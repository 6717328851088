import $ from 'jquery';
import { Position } from 'popper.js';
import { MaybeRef, computed, onBeforeUnmount, ref, unref, watch } from 'vue';
import { MaybeElement, unrefElement } from '@productiv/vue-shared';
import { isEqual } from 'lodash';

export type TooltipPlacement = Position | 'auto';
type TooltipOptions = {
  placement?: TooltipPlacement;
  boundary?: 'scrollParent' | 'window' | 'viewport';
};

/**
 * Renders a tooltip attached to an element. Note that a tooltip is distinct from a popover.
 * @param message Message to render
 * @param options Options for bootstrap's tooltip plugin
 * @returns element ref to attach to the element you want to render the tooltip on
 */
export default function useTooltip(
  message: MaybeRef<string | undefined>,
  options?: MaybeRef<TooltipOptions>,
) {
  const tooltipEl = ref<Exclude<MaybeElement, SVGElement> | null>(null);

  const defaultOptions = { placement: 'auto' as const };

  const watchSources = computed<
    [(typeof tooltipEl)['value'], string | undefined, TooltipOptions | undefined]
  >(() => [unref(tooltipEl), unref(message), unref(options) ?? defaultOptions]);

  watch(watchSources, ([el, tooltip, newOptions], [, oldTooltip, oldOptions]) => {
    const newEl = unrefElement(el);
    if (newEl) {
      if (tooltip) {
        if (oldTooltip !== tooltip || (newOptions && !isEqual(newOptions, oldOptions))) {
          // Dispose so the content updates, otherwise the previous tooltip
          // is preserved **if** it has been shown.
          $(newEl).tooltip('dispose');
        }

        $(newEl as HTMLElement).tooltip({
          ...newOptions,
          title: tooltip,
        });
      } else if (!tooltip && oldTooltip) {
        // Dispose instead of disable here so tooltip immediately is hidden
        $(newEl).tooltip('dispose');
      }
    }
  });

  onBeforeUnmount(() => {
    const el = unrefElement(tooltipEl);
    if (unref(message) && el) {
      $(el).tooltip('dispose');
    }
  });

  function hideTooltip() {
    const el = unref(tooltipEl);
    if (el && unref(message)) {
      $(el).tooltip('hide');
    }
  }

  return { el: tooltipEl, hideTooltip };
}
