<template>
  <StatusProvider v-bind="status">
    <BasePageContent>
      <template #[FAQLayout.TITLE]>
        <h1 class="mb-0">Help and FAQs</h1>
      </template>
      <template #[FAQLayout.SIDEBAR]>
        <BaseCard>
          <StatusSwitch>
            <template #loading>
              <SkeletonParagraph
                :paragraph-indent="0"
                :line-lengths="[100]"
                :line-height="10"
                :line-spacing="15"
                :paragraphs="7"
                :lines-per-paragraph="1"
              />
            </template>
            <template #default>
              <div class="faqs-links">
                <RouterLink
                  v-for="({ question }, i) in faqs"
                  :key="i"
                  class="d-flex pb-3 faq-link"
                  :to="{ name: 'faq', hash: `#faq${i}` }"
                >
                  {{ question }}
                </RouterLink>
              </div>
            </template>
          </StatusSwitch>
        </BaseCard>
      </template>
      <template #[FAQLayout.CONTENT]>
        <div class="faqs">
          <StatusSwitch>
            <template #loading>
              <SkeletonParagraph />
            </template>
            <template #default>
              <div
                v-for="({ question, answer }, i) in faqs"
                :key="i"
                :ref="(el) => (faqRefs[`faq${i}`] = el)"
                class="mb-5"
              >
                <h3>{{ question }}</h3>
                <p class="faq-answer">
                  {{ answer }}
                </p>
              </div>
            </template>
          </StatusSwitch>
        </div>
      </template>
    </BasePageContent>
  </StatusProvider>
</template>

<style scoped lang="scss">
.faq-answer {
  white-space: pre-wrap;
}
</style>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import StatusProvider from '@productiv/compass/components/Status/StatusProvider.vue';
import StatusSwitch from '@productiv/compass/components/Status/StatusSwitch.vue';
import SkeletonParagraph from '@productiv/compass/components/Status/SkeletonParagraph.vue';
import { FAQLayout } from './FAQLayout.vue';
import useFaqs from './useFaqs';
import useBackToTopButton from '@/toasts/useBackToTopButton';
import useScrollToRef from '@/catalog/useScrollToRef';
import BaseCard from '@/components/BaseCard.vue';
import BasePageContent from '@/components/BasePageContent.vue';

export default defineComponent({
  name: 'FAQPage',
  components: {
    BasePageContent,
    BaseCard,
    SkeletonParagraph,
    StatusProvider,
    StatusSwitch,
  },
  setup() {
    useBackToTopButton();
    const { faqs, status } = useFaqs();

    // these will be filled out during render by the function passed as the `ref` attribute.
    const faqRefs: Record<string, any> = reactive({});

    useScrollToRef(faqRefs);

    return {
      faqs,
      faqRefs,
      status,
      FAQLayout,
    };
  },
});
</script>
