import catalogBundle from '@/catalog';
import HeroSidebarLayout from '@/layouts/HeroSidebarLayout.vue';

export default {
  path: ':rest(.*)?',
  name: 'default-404',
  components: {
    header: catalogBundle.importComponent('AppHeader'),
    content: catalogBundle.importComponent('NotFoundPage'),
    layout: HeroSidebarLayout,
  },
  props: {
    layout: {
      id: '404',
    },
  },
};
