<template>
  <button
    v-bind="buttonAttributes"
    ref="buttonEl"
    :disabled="disabled || loading"
    class="btn shadow-none text-nowrap"
    :class="[
      `btn-${size}`,
      loading && 'btn-loading',
      theme === 'link' && 'p-0 border-0 align-baseline',
      block && 'btn-block',
      `btn-${theme}`,
    ]"
    :type="type"
    @click="clickHandler"
  >
    <BaseSpinner v-if="loading" :size="14" />
    <slot v-if="!loading" name="icon" />
    <slot />
    <slot name="right" />
  </button>
</template>

<script lang="ts">
import { omit } from 'lodash';
import { PropType, computed, defineComponent } from 'vue';

import { FULLY_COMPATIBLE } from '../utils/compat';
import { BUTTON_THEMES, STANDARD_SIZES } from '../constants';
import useTooltip, { TooltipPlacement } from '../compositions/useTooltip';
import BaseSpinner from './BaseSpinner.vue';

export default defineComponent({
  name: 'BaseButton',
  compatConfig: FULLY_COMPATIBLE,
  components: {
    BaseSpinner,
  },
  inheritAttrs: false,
  props: {
    /**
     * Button theme. Corresponds to main brand themes and colors.
     */
    theme: {
      type: String as PropType<(typeof BUTTON_THEMES)[number]>,
      validator(value: string) {
        return (
          BUTTON_THEMES.includes(value as any) ||
          /* NOTE(@alexv): used in PD header. Style is only defined in PD. */
          value === 'inverted-primary'
        );
      },
      default: 'primary',
    },
    /**
     * Controls whether or not the button is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, the button will display a loading spinner
     * and automatically become disabled. The icon slot
     * is not displayed in this state.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the size of the button.
     */
    size: {
      type: String as PropType<(typeof STANDARD_SIZES)[number]>,
      default: 'md',
      validator(size: unknown) {
        return STANDARD_SIZES.includes(size as any);
      },
    },
    /**
     * When true, the button will be rendered as a block element
     * (full width) rather than an inline element.
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * Maps directly to the HTML `type` attribute.
     */
    type: {
      type: String as PropType<'submit' | 'button' | 'reset'>,
      default: 'button',
    },
    /**
     * When present, hovering over the button presents the text in a tooltip.
     * Does not work on disabled buttons.
     * Pass an object to control the placement.
     */
    tooltip: {
      type: [String, Object] as PropType<string | { title: string; placement: TooltipPlacement }>,
      default: undefined,
    },
  },
  emits: {
    /**
     * Emitted when the button is clicked.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    click: (e: MouseEvent) => true,
  },
  setup(props, { emit }) {
    const { el: buttonEl, hideTooltip } = useTooltip(
      computed(() => (typeof props.tooltip === 'string' ? props.tooltip : props.tooltip?.title)),
      computed(() =>
        typeof props.tooltip === 'string' || !props.tooltip?.placement
          ? {}
          : { placement: props.tooltip.placement },
      ),
    );

    function clickHandler(event: MouseEvent) {
      emit('click', event);
      hideTooltip();
    }

    return {
      buttonEl,
      clickHandler,
    };
  },
  computed: {
    buttonAttributes() {
      return omit(this.$attrs, Object.keys(this.$props));
    },
  },
});
</script>
