import 'bootstrap';
import Popper from 'popper.js';
import set from 'lodash/set';

// theoretically fixes blurry tooltips.
// see: https://github.com/twbs/bootstrap/issues/23590
set(
  Popper.Defaults,
  'modifiers.computeStyle.gpuAcceleration',
  !(window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform)),
);

export * from 'bootstrap';
export { default as Popper } from 'popper.js';
