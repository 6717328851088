import * as Sentry from '@sentry/vue';
import { Router } from 'vue-router';
import findLast from 'lodash/findLast';
import { FeatureTeam } from '@/router/routeUtils';

export default function applyFeatureTeamTag(router: Router) {
  router.afterEach((to) => {
    const mostSpecificFeatureTeam = findLast(to.matched, ({ meta }) => Boolean(meta?.featureTeam))
      ?.meta.featureTeam as FeatureTeam;

    Sentry.configureScope((scope) => {
      scope.setTag('featureTeam', mostSpecificFeatureTeam ?? undefined);
    });
  });
}
