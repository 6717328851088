import { App } from 'vue';
import { Store } from 'vuex';
import { Userpilot } from 'userpilot';
import { Router } from 'vue-router';
import analyticsModule from './analyticsModule';

export { useTrackPage } from './useTrackPage';
export { useTrackEvent } from './useTrackEvent';
export { useAnalyticsContext } from './useAnalyticsContext';

export default {
  install(
    app: App,
    {
      store,
      router,
      isAnalyticsEnabled,
      additionalEventProperties,
      userpilotApiKey,
    }: {
      store: Store<any>;
      router: Router;
      isAnalyticsEnabled: boolean;
      additionalEventProperties: any;
      userpilotApiKey?: string;
    },
  ) {
    store.registerModule('analytics', analyticsModule);
    store.commit('analytics/setAdditionalEventProperties', { additionalEventProperties });

    if (isAnalyticsEnabled) {
      store.commit('analytics/enableAnalytics', { userpilotEnabled: Boolean(userpilotApiKey) });

      if (userpilotApiKey) {
        Userpilot.initialize(userpilotApiKey);

        router.afterEach(() => Userpilot.reload());
      }
    }
  },
};
