<template>
  <PortalOutlet :name="HeroSidebarOutlet.SUBNAV" />
  <div :id="id" class="pt-5 pb-2">
    <div class="container">
      <div class="row">
        <div class="col-9" :class="alignSidebar === 'left' ? 'offset-3' : ''">
          <PortalOutlet :name="HeroSidebarOutlet.TITLE" />
        </div>
        <div class="col-9" :class="alignSidebar === 'left' ? 'offset-3' : ''">
          <PortalOutlet :name="HeroSidebarOutlet.SUBTITLE" />
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-3 h-0" :class="alignSidebar === 'left' ? 'order-first' : 'order-last'">
        <div class="pb-4">
          <PortalOutlet :name="HeroSidebarOutlet.SIDEBAR" />
        </div>
      </div>
      <div class="col-9">
        <PortalOutlet name="alerts" />
        <PortalOutlet :name="HeroSidebarOutlet.CONTENT" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import PortalOutlet from '@/components/PortalOutlet.vue';

export enum HeroSidebarOutlet {
  SUBNAV = 'subnav',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  SIDEBAR = 'sidebar',
  CONTENT = 'content',
}

/**
 * Creates a three-panel layout with hero, sidebar, and
 * main content. The hero background is extended the full width
 * of the page, with the sidebar rendered to be overlapping
 * the hero background.
 *
 * When alignSidebar=left, the sidebar will be rendered to the
 * left of the hero title, subtitle, and main content and top-aligned
 * to the title. When alignSidebar=right, the sidebar will be
 * rendered to the right of the page and top-aligned with the subtitle.
 */
export default defineComponent({
  name: 'HeroSidebarLayout',
  components: {
    PortalOutlet,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    alignSidebar: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
  },
  setup() {
    return {
      HeroSidebarOutlet,
    };
  },
});
</script>
