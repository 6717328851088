import has from 'lodash/has';
import omit from 'lodash/omit';
import findKey from 'lodash/findKey';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import { App, Plugin } from 'vue';
import {
  LocationAsRelativeRaw,
  RouteLocationOptions,
  RouteLocationRaw,
  RouteQueryAndHash,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import { FeatureTeam, createForwardedViews, tagFeatureTeam } from './routeUtils';
import notFoundRoute from './notFoundRoute';

export type AsyncPlugin = Plugin;

interface SubmoduleInstallRecord {
  installed: boolean;
  providedRoutes: (string | symbol)[];
}

const submoduleInstallationCache: Record<string, SubmoduleInstallRecord> = {};

function installed(installPath: string): boolean {
  return submoduleInstallationCache[installPath]?.installed;
}

function isPlugin(moduleOrPlugin: Plugin | { default: Plugin }): moduleOrPlugin is Plugin {
  return !has(moduleOrPlugin, 'default');
}

function isNotNil<T>(value: T): value is Exclude<T, null | undefined> {
  return !isNil(value);
}

export function createSubmoduleMountPoint(
  path: string,
  {
    app,
    providedRouteNames,
    asyncModuleFactory,
    pluginOptions,
    featureTeam,
  }: {
    app: App;
    providedRouteNames: (string | symbol)[];
    asyncModuleFactory: () => Promise<{ default: AsyncPlugin } | AsyncPlugin>;
    pluginOptions: any;
    featureTeam: FeatureTeam;
  },
): RouteRecordRaw {
  submoduleInstallationCache[path] = {
    installed: false,
    providedRoutes: providedRouteNames,
  };
  const hostRouteName = Symbol(path);
  const defaultRouteName = Symbol(`${path}/default`);
  const catchallRouteName = Symbol(`${path}/404`);

  const pluginLoader = async () => {
    const resolvedModule = await asyncModuleFactory();
    app.use(isPlugin(resolvedModule) ? resolvedModule : resolvedModule.default, {
      ...pluginOptions,
      parentRouteName: hostRouteName,
      defaultRouteName,
    });
    submoduleInstallationCache[path].installed = true;
  };

  return tagFeatureTeam(
    {
      path: `${path}`,
      name: hostRouteName,
      redirect: { name: catchallRouteName },
      ...createForwardedViews('header', 'layout', 'content'),
      children: [
        {
          // NOTE(@alexv): this route does double duty: it acts as a catchall for all routes under /${path}
          // and when visited the first time (before the submodule is loaded), it loads the module
          // and processes an initial redirect. after the submodule is loaded, it acts as a normal 404 route.
          ...notFoundRoute,
          name: catchallRouteName,
          beforeEnter: async (to) => {
            let nextRouteName: RouteRecordName | null = Array.isArray(to.query.next)
              ? to.query.next[0]
              : to.query.next;
            if (nextRouteName && !providedRouteNames.includes(nextRouteName)) {
              nextRouteName = catchallRouteName;
            }
            const defaultRoute: RouteLocationRaw = {
              replace: true,
              // when ?next= is used (from a CrossLink), the other QPs are used
              // to carry the route params, so there's no way (right now) to carry QPs as well.
              query: nextRouteName ? undefined : omit(to.query, 'next'),
              params: nextRouteName
                ? mapValues(omit(to.query, 'next'), (qp) =>
                    Array.isArray(qp) ? filter(qp, isNotNil) : qp,
                  )
                : undefined,
              hash: to.hash,
              name: nextRouteName || defaultRouteName,
            };
            if (!installed(path)) {
              // First time entering this route. Install our module, which is expected
              // to attach child routes to this host route.
              await pluginLoader();

              if (to.path.endsWith(path) || to.path.endsWith(`${path}/`)) {
                // If the path we are navigating to is the same as the root path, we
                // should redirect to the "default" route for the module (or the 'next' route).
                // In more practical terms: if the mount point is 'catalog' and someone
                // navigates to /catalog (or /catalog/), we should load the module and
                // then redirect to /catalog/all.
                return defaultRoute;
              }

              // However, if we are navigating to some other route underneath this root
              // (such as /catalog/all), we need to re-resolve the route (since the module
              // has now installed additional routes that may match better). Vue-router will
              // do this for us if we return an object like { replace: true, path: to.path }.
              // Path params will automatically be extracted and passed along when the router
              // parses + resolves path, but we need to explicitly pass query + hash through.
              return {
                replace: true,
                path: to.path,
                query: to.query,
                hash: to.hash,
              };
            }
          },
        },
      ],
    },
    featureTeam,
  );
}

export function resolveCrossLink(
  route: RouteQueryAndHash & LocationAsRelativeRaw & RouteLocationOptions,
): RouteLocationRaw {
  if (!route.name) {
    throw new Error('pass a route with name');
  }

  const submoduleRootPath = findKey(submoduleInstallationCache, ({ providedRoutes }) =>
    providedRoutes.includes(route.name!),
  );
  if (!submoduleRootPath) {
    throw new Error(`No known submodule provides route with name ${String(route.name)}`);
  }

  if (installed(submoduleRootPath)) {
    return route; // no need to do anything
  }
  return {
    path: `/${submoduleRootPath}`,
    query: {
      next: route.name.toString(),
      ...route.params,
      ...route.query,
    },
  };
}
