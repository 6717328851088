import { App } from 'vue';
import { Store } from 'vuex';
import axios from 'axios';
import apiModule from './apiModule';

export default {
  install(app: App, { store, baseUrl }: { store: Store<any>; baseUrl: string }) {
    axios.defaults.baseURL = baseUrl;
    store.registerModule('api', apiModule);
    store.commit('api/setBaseUrl', { baseUrl });
  },
};
