import { App, Component } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw } from 'vue-router';
import { Store } from 'vuex';
import findLast from 'lodash/findLast';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import authModule from './authModule';
import UserLogout from './UserLogout.vue';
import AssumeRole from './AssumeRole.vue';
import RevertRole from './RevertRole.vue';
import LoginCallback from './LoginCallback.vue';
import LoginForm from './LoginForm.vue';
import { LOGIN_ROUTE_NAMES } from './constants';

export interface LoginOptions<TStore> {
  store: Store<TStore>;
  router: Router;
  LoginViewComponent: Component;
}

function getLoginRoutes(LoginViewComponent: Component): RouteRecordRaw[] {
  return [
    {
      path: '/login',
      redirect: {
        name: LOGIN_ROUTE_NAMES.USER_LOGIN,
      },
      component: LoginViewComponent,
      props: (route) => ({ error: route.query.error }),
      children: [
        {
          path: '',
          name: LOGIN_ROUTE_NAMES.USER_LOGIN,
          component: LoginForm,
        },
      ],
    },
    {
      path: '/logout',
      name: LOGIN_ROUTE_NAMES.USER_LOGOUT,
      component: UserLogout,
    },
    {
      path: '/assume-role',
      name: LOGIN_ROUTE_NAMES.ASSUME_ROLE,
      component: AssumeRole,
    },
    {
      path: '/revert-role',
      name: LOGIN_ROUTE_NAMES.REVERT_ROLE,
      component: RevertRole,
    },
    {
      path: '/callback',
      name: LOGIN_ROUTE_NAMES.LOGIN_OAUTH2_CALLBACK,
      component: LoginCallback,
    },
  ];
}

function isAuthenticatedRoute(route: RouteLocationNormalized) {
  return findLast(route.matched, (route) => isBoolean(get(route, 'meta.authenticated')))?.meta
    .authenticated;
}

function verifyRouteAuthentication<TStore>(
  store: Store<TStore>,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (isAuthenticatedRoute(to) && !store.getters['auth/isLoggedIn']) {
    next({
      replace: true,
      name: 'user-login',
      query: {
        ...to.query,
        next: to.path,
      },
    });
  } else {
    next();
  }
}

export function authenticatedRoute(route: RouteRecordRaw) {
  return {
    ...route,
    meta: {
      ...route.meta,
      authenticated: true,
    },
  };
}

export default {
  install<TStore>(app: App, { store, router, LoginViewComponent }: LoginOptions<TStore>) {
    getLoginRoutes(LoginViewComponent).forEach((route) => {
      router.addRoute(route);
    });

    store.registerModule('auth', authModule);

    // NOTE(@alexv): the router resolves the current/initial route from the query when
    // app.use(router) is called. we have to call that after app.use(loginPlugin) so
    // that the router can resolve our dynamically-added routes, which means that router.currentRoute
    // is always the root `/` route at this point. we can still resolve the route manually, though,
    // since we have already installed our login routes above
    const currentRoute = router.resolve(window.location.pathname + window.location.search);
    // skip restoring the auth state on the callback route. otherwise, we will trigger an infinite loop!
    const stateReady =
      currentRoute.name !== LOGIN_ROUTE_NAMES.LOGIN_OAUTH2_CALLBACK
        ? store.dispatch('auth/restoreAuthTokens', {
            query: {
              ...currentRoute.query,
              next: currentRoute.path,
            },
          })
        : Promise.resolve();
    router.beforeEach(async (...args) => {
      await stateReady;
      verifyRouteAuthentication.call(router, store, ...args);
    });
  },
};
