<template>
  <div class="bg-white border rounded p-4">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Simple component to generate a card with box shadow.
 */
export default defineComponent({
  name: 'BaseCard',
});
</script>
