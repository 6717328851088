import axios from 'axios';
import { AUTH_PROVIDER } from './AuthClients';

interface LoginConfigurationBase {
  authProvider: AUTH_PROVIDER;
  idp?: string;
}

type LoginConfigurationResponse = LoginConfigurationBase & AuthClientBaseClientConfig;

export async function getLoginConfiguration(
  domainName: string,
  idp: string,
): Promise<LoginConfigurationResponse> {
  return (
    await axios.get('auth/loginConfiguration', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      params: { domain: domainName, target_cid: idp },
    })
  ).data;
}

export async function getUserInfo() {
  return (await axios.get('auth/oauth2/userinfo', { withCredentials: true })).data;
}
