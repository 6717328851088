import { Plugin } from 'vue';
import toastPlugin from './toasts';

const compassPlugin: Plugin = {
  install(app) {
    app.use(toastPlugin);
  },
};

export default compassPlugin;
