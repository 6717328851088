<template>
  <BaseContentSkeleton
    v-bind="$attrs"
    :width="width"
    :height="paragraphs * linesPerParagraph * lineOffset"
  >
    <template v-for="i in paragraphs" :key="i">
      <rect x="0" :y="getYPos(i, 0)" rx="3" ry="3" :width="getLineWidth(0)" :height="lineHeight" />
      <rect
        v-for="j in linesPerParagraph"
        :key="j"
        :x="j === 1 ? 0 : paragraphIndent"
        :y="getYPos(i, j)"
        rx="3"
        ry="3"
        :width="getLineWidth(j)"
        :height="lineHeight"
      />
    </template>
  </BaseContentSkeleton>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FULLY_COMPATIBLE } from '../../utils/compat';
import BaseContentSkeleton from './BaseContentSkeleton.vue';

export default defineComponent({
  name: 'SkeletonParagraph',
  components: {
    BaseContentSkeleton,
  },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Controls the number of paragraphs in the skeleton.
     */
    paragraphs: {
      type: Number,
      default: 2,
    },
    /**
     * Controls the number of lines in each paragraph.
     */
    linesPerParagraph: {
      type: Number,
      default: 5,
    },
    /**
     * Controls the height (in svg units) of each line in a paragraph.
     */
    lineHeight: {
      type: Number,
      default: 10,
    },
    /**
     * Controls the amount of space (in svg units) between each line in a paragraph.
     */
    lineSpacing: {
      type: Number,
      default: 10,
    },
    /**
     * Controls how deep (in svg units) each paragraph is indented.
     */
    paragraphIndent: {
      type: Number,
      default: 20,
    },
    /**
     * Controls the length of each line (in svg units) in a paragraph. If
     * `linesPerParagraph` exceeds the length of this array, it will wrap around.
     */
    lineLengths: {
      type: Array as PropType<number[]>,
      default: () => [250, 220, 170, 200, 80],
      validator(lengths) {
        return (
          Array.isArray(lengths) && lengths.length > 0 && lengths.every((length) => length > 0)
        );
      },
    },
  },
  computed: {
    lineOffset(): number {
      return this.lineHeight + this.lineSpacing;
    },
    width(): number {
      return Math.max(...this.lineLengths);
    },
  },
  methods: {
    // "v-for i in 10" iterates 1..10 not 0..9
    getYPos(row: number, offset: number) {
      return (offset - 1 + (row - 1) * this.linesPerParagraph) * this.lineOffset;
    },
    getLineWidth(line: number) {
      return this.lineLengths[(line - 1) % this.lineLengths.length];
    },
  },
});
</script>
