// NOTE(@towersadam): importing like this fixes an issue where jest could not resolve Bloodhound
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import { union } from 'lodash';
import { Ref, computed, unref, toRaw, reactive, MaybeRef } from 'vue';

export enum Tokenizers {
  whitespace = 'whitespace',
  nonword = 'nonword',
  ngram = 'ngram',
}

type BloodhoundInstance<T> = {
  search(query: string, cb: (matches: T[]) => void): void;
};

export default function useBloodhound<T>(
  items: Ref<T[] | ((searchText: string) => Promise<T[]>)>,
  {
    itemIdentifier,
    itemDisplayNameKey,
    itemIndexKey,
    queryTokenizer = Tokenizers.whitespace,
  }: {
    itemIdentifier: MaybeRef<keyof T>;
    itemDisplayNameKey: MaybeRef<keyof T>;
    itemIndexKey?: MaybeRef<keyof T>;
    queryTokenizer?: Tokenizers;
  },
): Ref<BloodhoundInstance<T>> {
  function datumTokenizer(string) {
    return union(Bloodhound.tokenizers.whitespace(string), Bloodhound.tokenizers.nonword(string));
  }

  const bloodhound = computed(() => {
    // observe the ref unreactively to clean it up in case it already exists.
    toRaw(reactive({ bloodhound })).bloodhound?.clear?.();

    const itemIdKey = unref(itemIdentifier);
    const itemSearchKey = unref(itemIndexKey) ?? unref(itemDisplayNameKey);

    return new Bloodhound({
      local: unref(items),
      identify: (item) => item[itemIdKey],
      datumTokenizer: (item) => datumTokenizer(item[itemSearchKey]),
      queryTokenizer: Bloodhound.tokenizers[queryTokenizer],
    });
  });

  return bloodhound;
}

export function useBloodhoundResults<T>(
  bhRef: Ref<BloodhoundInstance<T>>,
  query: Ref<string | null>,
) {
  return computed<T[]>(() => {
    let bhMatches: T[];
    unref(bhRef).search(unref(query) ?? '', (matches) => {
      // Guaranteed to be sync callback, per bloodhound docs
      bhMatches = matches;
    });
    return bhMatches!;
  });
}
