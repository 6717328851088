import { FormKitTypeDefinition } from '@formkit/core';

export default {
  type: 'group',
  schema: [
    {
      $formkit: 'text',
      label: '$label',
      id: '$id',
      help: '$help',
      bind: '$attrs',
      disabled: '$disabled',
      name: 'displayName',
      validation: [['required']],
      classes: {
        inner: 'border-0 rounded-sm overflow-hidden',
        input: 'bg-white opacity-1',
      },
      sectionsSchema: {
        prefix: {
          children: [
            {
              $el: 'img',
              if: '$node.parent._value.iconUrl',
              attrs: { src: '$node.parent._value.iconUrl', width: 32, height: 32 },
            },
          ],
        },
      },
    },
  ],
} as FormKitTypeDefinition;
