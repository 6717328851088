import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios, { AxiosInstance } from 'axios';
import * as Sentry from '@sentry/browser';
import noop from 'lodash/noop';

interface ApiModuleState {
  targetCustomerId: string | null;
  baseUrl: string | null;
}

const createState: () => ApiModuleState = () => ({
  baseUrl: null,
  targetCustomerId: null,
});

const mutations: MutationTree<ApiModuleState> = {
  setCustomerOverride(state, { customerId }: { customerId: string }) {
    state.targetCustomerId = customerId;
  },
  setBaseUrl(state, { baseUrl }: { baseUrl: string }) {
    state.baseUrl = baseUrl;
  },
};

const getters: GetterTree<ApiModuleState, {}> = {
  authenticatedAxiosInstance(state, _, __, rootGetters): AxiosInstance | null {
    if (!rootGetters['auth/isLoggedIn']) {
      return null;
    }
    const instance = axios.create({
      withCredentials: true,
      // by allowing 404s to be non-error responses, axios automatically makes the `data` null.
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });
    instance.interceptors.request.use(
      (config) => {
        return {
          ...config,
          params: {
            ...config.params,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            target_cid: state.targetCustomerId,
          },
        };
      },
      undefined,
      { runWhen: () => Boolean(state.targetCustomerId), synchronous: true },
    );
    instance.interceptors.response.use(
      undefined,
      function (error) {
        if (axios.isCancel(error)) {
          // don't report cancellations - they are generally expected errors
          return error;
        }

        if (error.response?.status === 401) {
          // PEAS will refresh idtoken from refresh token on startup.
          // FIXME(@alexv): reauth through auth service
          window.location.reload();
          return new Promise(noop);
        }
        if (error.response?.status !== 422 && error.response?.status < 500) {
          Sentry.captureException(error);
        }
        throw error;
      },
      { synchronous: true },
    );

    return instance;
  },
  currentCustomerOverride(state) {
    return state.targetCustomerId;
  },
};

const actions: ActionTree<ApiModuleState, {}> = {
  sendBeacon({ state }, { api, params }) {
    if (navigator.sendBeacon) {
      const blob = new Blob([JSON.stringify({ body: params })], {
        type: 'text/plain; charset=UTF-8',
      });
      const url = new URL(api, state.baseUrl!);
      navigator.sendBeacon(url.href, blob);
    }
  },
};

export default {
  namespaced: true,
  state: createState,
  mutations,
  getters,
  actions,
};
