import { MaybeRef, Ref, computed, unref } from 'vue';
import { sortBy, min, take, isEmpty } from 'lodash';
import { OmniSearchItem } from './omniSearch.types';

const moveDisabledToEnd = (results: OmniSearchItem[] = []) => {
  return sortBy(results, ({ disabled }) => disabled ?? false);
};

export const useOmniSearchLimits = (
  rawSearchResults: Ref<OmniSearchItem[]>,
  {
    disabled,
    searchText,
    emptySearchItems,
    shownSuggestionsCap,
    maxResults,
  }: {
    disabled?: MaybeRef<boolean>;
    searchText: MaybeRef<string | null>;
    emptySearchItems?: MaybeRef<OmniSearchItem[]>;
    shownSuggestionsCap: MaybeRef<number>; // Applies only to actual search results, not empty results
    maxResults: MaybeRef<number>; // Hard-limit on number of things returned - applies to every kind of results
  },
) => {
  const results = computed(() => {
    if (unref(disabled)) {
      return {
        results: [],
        capped: false,
      };
    }

    let items: OmniSearchItem[];
    let limit: number;
    if (isEmpty(unref(searchText))) {
      items = unref(emptySearchItems) ?? [];
      limit = unref(maxResults);
    } else {
      items = moveDisabledToEnd(unref(rawSearchResults));
      limit = min([unref(maxResults), unref(shownSuggestionsCap)])!;
    }

    return {
      results: take(items, limit),
      capped: items.length > unref(maxResults), // The shownSuggestionsCap should not trigger the alert
    };
  });

  return {
    cappedResults: computed(() => unref(results).results),
    areResultsCapped: computed(() => unref(results).capped),
  };
};
