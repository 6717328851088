<template>
  <BaseIcon
    class="spinner"
    name="loop"
    :size="size"
    data-testid="spinner"
    aria-label="Data Loading Indicator"
  />
</template>

<style scoped lang="scss">
.spinner {
  display: inline-block;
  animation: spin 0.7s infinite linear;
}

@keyframes spin {
  from {
    transform: scaleX(-1) rotate(360deg);
  }
  to {
    transform: scaleX(-1) rotate(0deg);
  }
}
</style>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ICON_SIZES } from '../constants';
import { FULLY_COMPATIBLE } from '../utils/compat';
import BaseIcon from './BaseIcon.vue';

export default defineComponent({
  name: 'BaseSpinner',
  components: { BaseIcon },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Controls the icon size of the spinner.
     */
    size: {
      type: Number as PropType<(typeof ICON_SIZES)[number]>,
      default: undefined,
    },
  },
});
</script>
