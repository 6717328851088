<template>
  <OmniSearchDatasetResults
    :id="id"
    :results="resultsToRender"
    :are-results-capped="areResultsCapped"
    :item-identifier="itemIdentifier"
    :item-display-name-key="itemDisplayNameKey"
    :num-capped-results="numCappedResults"
    :allow-show-more="allowShowMore"
    v-bind="renderingProps"
    @select="(item) => $emit('select', item)"
    @create="(text) => $emit('create', text)"
    @show-more="allowShowMore && (isShowingMore = true)"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </OmniSearchDatasetResults>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, toRef, toRefs, unref, watch } from 'vue';
import { sortBy, pick, keys, isBoolean, isEmpty } from 'lodash';

import { FULLY_COMPATIBLE } from '../../utils/compat';

import { AsyncStatus } from '../Status/statusContext';
import useBloodhound, { useBloodhoundResults } from './useBloodhound';
import { useConsumeOmniSearchContext } from './useOmniSearchContext';
import OmniSearchDatasetResults from './OmniSearchDatasetResults.vue';
import {
  OmniSearchItem,
  OmniSearchDatasetRenderingProps,
  OmniSearchLocalDatasetProps,
  OmniSearchDatasetEvents,
} from './omniSearch.types';
import { useOmniSearchLimits } from './useOmniSearchLimits';
import { appendCreateItem, extractExactMatches } from './resultsUtils';

export default defineComponent({
  name: 'OmniSearchLocalDataset',
  components: {
    OmniSearchDatasetResults,
  },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    status: {
      type: Object as PropType<AsyncStatus>,
      default: undefined,
    },
    ...OmniSearchLocalDatasetProps,
  },
  emits: {
    ...OmniSearchDatasetEvents,
  },
  setup(props) {
    const { items, itemIdentifier, itemIndexKey, itemDisplayNameKey } = toRefs(props);

    const context = useConsumeOmniSearchContext();

    watch(
      toRef(props, 'status'),
      (newStatus, oldStatus) => {
        if (!newStatus) {
          return;
        }
        if (oldStatus?.loading === newStatus.loading) {
          return;
        }
        if (newStatus.loading) {
          context.emit('loading:start', { id: props.id });
        } else {
          context.emit('loading:end', { id: props.id });
        }
      },
      { immediate: true },
    );

    // We want the first empty input event to trigger an update, so we start at null instead of ''
    const searchText = ref<string | null>(null);
    context.on('input', (newSearchText) => {
      searchText.value = newSearchText;
    });

    const bloodhound = useBloodhound<OmniSearchItem>(items, {
      itemIdentifier: unref(itemIdentifier) as any,
      itemIndexKey: unref(itemIndexKey) as any,
      itemDisplayNameKey: unref(itemDisplayNameKey) as any,
    });
    const rawSearchResults = useBloodhoundResults(bloodhound, searchText);

    const sortedResults = computed(() => {
      if (!props.sorted && !props.allowCreate) {
        return unref(rawSearchResults); // Use the exact order of items provided to the dataset
      }

      const sortKey = isBoolean(props.sorted) ? props.itemDisplayNameKey : props.sorted;
      if (!props.allowCreate) {
        return sortBy(unref(rawSearchResults), sortKey);
      }

      // For allowCreate to make sense, we need to be sure there isn't already a matching item in the results, so
      // we sort exact matches to the top so the user will see that option first.
      const [exactMatches, remainingItems] = extractExactMatches(
        unref(rawSearchResults),
        unref(itemDisplayNameKey),
        unref(searchText),
      );

      if (!props.sorted) {
        // Client doesn't want other sorting so we leave the rest of the items unchanged.
        return [...exactMatches, ...remainingItems];
      }

      const sortedRemainder = sortBy(remainingItems, sortKey);
      return [...exactMatches, ...sortedRemainder];
    });

    const { disabled, shownSuggestionsCap, maxResults, emptySearchItems } = toRefs(props);
    const isShowingMore = ref(false);
    context.on('closed', () => {
      isShowingMore.value = false;
    });

    const { cappedResults, areResultsCapped } = useOmniSearchLimits(sortedResults, {
      disabled,
      searchText,
      emptySearchItems,
      shownSuggestionsCap: computed(() =>
        unref(isShowingMore) ? Infinity : unref(shownSuggestionsCap),
      ),
      maxResults: computed(() => (unref(isShowingMore) ? Infinity : unref(maxResults))),
    });
    const numCappedResults = computed(
      () => unref(sortedResults).length - unref(cappedResults).length,
    );

    const resultsToRender = computed(() => {
      const results = unref(cappedResults);

      const currentSearch = unref(searchText);
      if (!props.allowCreate || isEmpty(currentSearch)) {
        return results;
      }

      return appendCreateItem(results, currentSearch, toRefs(props));
    });

    const renderingProps = computed(() => pick(props, keys(OmniSearchDatasetRenderingProps)));

    return {
      renderingProps,
      resultsToRender,
      areResultsCapped,
      isShowingMore,
      numCappedResults,
    };
  },
});
</script>
