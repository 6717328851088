import { AsyncComponentLoader, Component, defineAsyncComponent, h, ref } from 'vue';
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import * as Sentry from '@sentry/browser';
import * as Environment from '@/utils/environment';

// NOTE(@alexv): prevent tests from needing to use jest.runTimersToTime(200) every time
// they want to wait for one of these to show up
export const LOADING_COMPONENT_DELAY = process.env.NODE_ENV === 'test' ? 0 : 200;

/**
 * Wraps an async component in a spinner and automatically handles errors
 * if the component cannot resolve.
 * @param asyncComponentLoader Underlying component (such as one from an `import()`) to load asynchronously
 * @see defineAsyncComponent https://github.com/vuejs/vue-next/blob/3efa2aff13f99175357a465ef4ce281ac8148ede/packages/runtime-core/src/apiAsyncComponent.ts#L41
 */
export function useAsyncComponent<TComponent extends Component>(
  asyncComponentLoader: AsyncComponentLoader<TComponent>,
) {
  const error = ref<Error | undefined>();
  const retry = ref<(() => void) | undefined>();

  return defineAsyncComponent<TComponent>({
    loader: asyncComponentLoader,
    delay: LOADING_COMPONENT_DELAY,
    loadingComponent: () =>
      // NOTE(@alexv): Normally, you would use the errorComponent option to handle errors.
      // However, when onError() is supplied, the async component _will not finish loading_
      // until either the reload() or fail() callbacks given to onError are called. If we want
      // to stop rendering a spinner and instead render the error + retry button once the underlying
      // loader errors out, we can't call reload() until the user clicks the button, but we *also*
      // can't call fail() (to render an errorComponent with the retry button) after setting
      // `retry.value` in order to reject the Promise and show the errorComponent, since then
      // calling retry() later would attempt to re-resolve an already failed promise.
      // So, since we have to force the underlying Promise "open" (ie, stall Promise resolution
      // indefinitely in failure cases), we have to handle both error and loading cases within
      // the loadingComponent.
      h(StatusIndicator, {
        loading: !error.value,
        errors: error.value ? { errors: error.value } : undefined,
        retry: retry.value,
      }),
    onError: (err, reload, fail, retries) => {
      // NOTE(@alexv): This function is invoked when the Promise returned by `asyncComponentLoader` is rejected.
      // reload() will re-invoke `asyncComponentLoader` to create a new Promise and the underlying async component
      // resolution will depend on the resolution of the new Promise. Calling `fail()` will reject the underlying promise;
      // as far as I can tell, once the Promise is rejected, only a full remount will trigger a new attempt.
      if (retries === 0) {
        // NOTE(@alexv): remounts _will not_ automatically trigger additional retries
        // _when the Promise is still pending_, since Vue re-uses the same Promise
        // while it is still pending (event between remounts) - without this first retry,
        // the app will never retry again until the user clicks "Retry". (Also, the Promise will
        // never ever resolve and live in memory forever. Too bad!)
        reload();
        return;
      }
      console.error(err);
      if (Environment.IS_SENTRY_ENABLED) {
        Sentry.captureException(err);
      }
      error.value = err;
      retry.value = () => {
        error.value = undefined;
        reload();
      };
    },
  });
}
