<template>
  <div
    id="main"
    class="vh-100 container-fluid d-flex flex-column align-items-center justify-content-center"
  >
    <div class="d-flex">
      <img
        class="productiv-company-logo"
        src="@/assets/Productiv_Home_Logo.svg"
        alt="Productiv Logo"
        width="132"
        height="32"
      />
    </div>
    <div class="container py-3 my-4 bg-white">
      <div class="row justify-content-center">
        <div class="col-6">
          <h1 class="text-center mb-4">Sign in</h1>
          <RouterView />
        </div>
      </div>
    </div>
    <a href="https://productiv.com" class="d-block mx-auto">productiv.com</a>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  width: 300px;
  object-fit: cover;
}

#main {
  background-color: palette-color('blue', 'lighter');
}
</style>

<script lang="ts">
import { defineComponent, PropType, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import useToasts from '@productiv/compass/compositions/useToasts';

export default defineComponent({
  name: 'Login',
  props: {
    error: {
      type: String as PropType<string>,
      default: null,
    },
  },
  setup(props) {
    const { error } = toRefs(props);
    const toast = useToasts();
    const router = useRouter();
    watch(
      error,
      (error) => {
        if (error) {
          toast.showErrorToast(error);
          router.replace({ query: { error: undefined } });
        }
      },
      { immediate: true },
    );

    return {};
  },
});
</script>
