import { Component, defineAsyncComponent, DefineComponent } from 'vue';
import { useAsyncComponent } from './utils/useAsyncComponent';

type ComponentExports<TBundle> = {
  // NOTE(@alexv): DefineComponent<{}, {}, any> is the type that shims-vue.d.ts declares
  // that *.vue files export, but when editing .vue files, the generic parameters
  // of this type are actually inferred properly by Vetur and don't match anymore. We
  // can work around this by using `infer` to force typescript to correctly identify what
  // types are supposed to fill these slots without resorting to `any` - which causes the
  // conditional type to consider pretty much every type as a component.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof TBundle]: TBundle[K] extends DefineComponent<infer P, infer B, any> ? K : never;
}[keyof TBundle];

type ModuleExports<TBundle> = {
  [K in keyof TBundle]: K extends ComponentExports<TBundle> ? never : K;
}[keyof TBundle];

interface AsyncBundleImporter<TBundle> {
  importComponent: <TKey extends ComponentExports<TBundle>>(
    componentName: TKey,
  ) => ReturnType<typeof defineAsyncComponent>;
  importModule: <TKey extends ModuleExports<TBundle>>(moduleName: TKey) => Promise<TBundle[TKey]>;
  getAllExports: () => Promise<TBundle>;
}

/**
 * Create a bundle importer which can be used to retrieve exports
 * from a bundle hosted in a different chunk of the app
 * @param asyncImporter Bundle importer factory. This function is re-invoked
 * for every call to the importer (it is intended to be used with webpack's import(),
 * which provides its own promise caching).
 */
export function createImporter<TBundle>(
  asyncImporter: () => Promise<TBundle>,
): AsyncBundleImporter<TBundle> {
  return {
    importComponent: (componentName) => {
      return useAsyncComponent(async () => {
        const resolvedModule = await asyncImporter();
        return resolvedModule[componentName] as Component;
      });
    },
    importModule: async (moduleName) => {
      const resolvedModule = await asyncImporter();
      return resolvedModule[moduleName];
    },
    getAllExports: asyncImporter,
  };
}
