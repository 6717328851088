<template>
  <BaseButton
    class="icon-button"
    :class="[buttonTheme !== 'link' && 'p-1']"
    :size="size"
    :theme="buttonTheme"
    :disabled="disabled"
    :loading="loading"
    :tooltip="tooltip"
    @click="(...args) => $emit('click', ...args)"
  >
    <template v-if="!loading">
      <BaseIcon
        v-if="iconSet === 'material'"
        :name="iconName"
        :theme="iconTheme as any"
        :size="iconSize"
      />
      <BaseSvgIcon v-else :name="iconName" :theme="iconTheme as any" :size="iconSize" />
    </template>
  </BaseButton>
</template>

<style lang="scss" scoped>
.icon-button:not(.btn-link) {
  // fixes a height issue on small size buttons. doesn't have any effect on md or lg
  line-height: 14px;

  :deep(.material-icons),
  :deep(.fontawesome-icons) {
    margin: -1px; // cancel out border
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, toRefs, unref } from 'vue';
import { FULLY_COMPATIBLE } from '../utils/compat';
import { STANDARD_SIZES, BUTTON_THEMES, ICON_THEMES, FONT_AWESOME_ICON_THEMES } from '../constants';
import { TooltipPlacement } from '../compositions/useTooltip';
import BaseButton from './BaseButton.vue';
import BaseIcon from './BaseIcon.vue';
import BaseSvgIcon from './BaseSvgIcon.vue';

const BUTTON_SIZE_TO_ICON_SIZE = {
  sm: 16, // 24px square
  md: 24, // 32px square
  lg: 32, // 40px square
} as const;

export default defineComponent({
  name: 'IconButton',
  compatConfig: FULLY_COMPATIBLE,
  components: {
    BaseButton,
    BaseIcon,
    BaseSvgIcon,
  },
  props: {
    /**
     * Controls whether the button is disabled.
     */
    disabled: BaseButton.props.disabled,
    /**
     * Name of the icon to be rendered (main button content). Corresponds to BaseIcon names
     * if iconSet is material, otherwise corresponds to BaseSvgIcon names.
     */
    iconName: {
      type: String,
      required: true,
    },
    /**
     * Icon theme. Corresponds to &lt;BaseIcon&gt; themes if iconSet is
     * 'material', and &lt;BaseSvgIcon&gt; themes if it is set to 'fa'.  If not
     * passed, the default value of the theme prop on those components will be
     * used.
     */
    iconTheme: {
      type: String as PropType<(typeof ICON_THEMES | typeof FONT_AWESOME_ICON_THEMES)[number]>,
      default: undefined,
      validator(value) {
        return (
          ICON_THEMES.includes(value as any) || FONT_AWESOME_ICON_THEMES.includes(value as any)
        );
      },
    },
    /**
     * Controls the size of the button.
     */
    size: {
      type: String as PropType<(typeof STANDARD_SIZES)[number]>,
      default: 'md',
      validator(value) {
        return STANDARD_SIZES.includes(value as any);
      },
    },
    /**
     * Button theme. Corresponds to main brand themes and colors.
     */
    buttonTheme: {
      type: String as PropType<(typeof BUTTON_THEMES)[number]>,
      default: 'secondary',
      validator(value) {
        return (
          BUTTON_THEMES.includes(value as any) ||
          /* NOTE(@alexv): used in PD header. Style is only defined in PD. */
          value === 'inverted-primary'
        );
      },
    },
    /**
     * Icon family to use (Material Icons or Font Awesome).
     */
    iconSet: {
      type: String as PropType<'material' | 'fa'>,
      default: 'material',
      validator(value) {
        return value === 'material' || value === 'fa';
      },
    },
    /**
     * Renders a loading spinner inside the button instead of the given icon.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * When present, hovering over the button presents the text in a tooltip.
     * Check BaseButton for more details.
     */
    tooltip: {
      type: [String, Object] as PropType<string | { title: string; placement: TooltipPlacement }>,
      default: undefined,
    },
  },
  emits: [
    /**
     * Emitted when the button is clicked.
     */
    'click',
  ],
  setup(props) {
    const { size } = toRefs(props);

    const iconSize = computed(() => BUTTON_SIZE_TO_ICON_SIZE[unref(size)]);

    return {
      iconSize,
    };
  },
});
</script>
