<template>
  <div class="w-100 p-5 m-5 d-flex justify-content-center lead">
    Signing in
    <div class="mx-2">
      <StatusIndicator loading />
    </div>
  </div>
</template>

<script>
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import { mapActions, mapGetters } from 'vuex';
import { LOGIN_ROUTE_NAMES } from './constants';

export default {
  name: 'LoginCallback',
  components: {
    StatusIndicator,
  },
  computed: {
    ...mapGetters({
      returnUrl: 'auth/returnUrl',
      isLoggedIn: 'auth/isLoggedIn',
      lastLoginError: 'auth/lastLoginError',
    }),
  },
  async mounted() {
    await this.logIn({
      query: this.$route.query,
    });
    if (this.isLoggedIn) {
      this.$router.replace(this.returnUrl || '/');
    } else {
      this.$router.replace({
        name: LOGIN_ROUTE_NAMES.USER_LOGIN,
        query: {
          error: this.lastLoginError,
        },
      });
    }
  },
  methods: {
    ...mapActions({
      logIn: 'auth/completeLogin',
    }),
  },
};
</script>
