import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import constant from 'lodash/constant';
import { RouteRecordRaw, RouterView } from 'vue-router';

export type FeatureTeam = 'ITOps' | 'AppCenter' | 'Admin and Infra';

export function createForwardedViews(...viewNames: string[]) {
  const keyedNames = keyBy(viewNames);
  return {
    components: {
      default: RouterView,
      ...mapValues(keyedNames, constant(RouterView)),
    },
    props: mapValues(keyedNames, (name) => ({ name })),
  };
}

export function tagFeatureTeam(route: RouteRecordRaw, featureTeam: FeatureTeam) {
  return {
    ...route,
    meta: {
      ...(route.meta ?? {}),
      featureTeam,
    },
  };
}
