<template>
  <div
    class="banner text-white d-flex align-items-center justify-content-center py-2"
    :class="`bg-${theme}`"
  >
    <slot name="message" />
    <BaseButton :theme="theme" class="ml-3 px-0 return-button" @click="$emit('click')">
      <i class="material-icons">keyboard_return</i>
      <slot name="button-text" />
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
.return-button {
  border: 2px solid $white;
  &:hover,
  &:focus {
    border: 2px solid transparent;
  }
}
</style>

<script>
import BaseButton from '@productiv/compass/components/BaseButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BasePageBanner',
  components: {
    BaseButton,
  },
  props: {
    theme: {
      type: String,
      validator(value) {
        return [
          'primary',
          'secondary',
          'link',
          'black',
          'light',
          'danger',
          'red',
          'blue',
          'orange',
          'green',
          'purple',
          'segmented',
          'outline-primary',
          'outline-secondary',
        ].includes(value);
      },
      default: 'primary',
    },
  },
  emits: ['click'],
});
</script>
