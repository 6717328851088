import { Plugin } from 'vue';
import { POSITION, TYPE, PluginOptions, createToastInterface } from 'vue-toastification';
import { ToastContent, ToastID, ToastOptions } from 'vue-toastification/dist/types/types';

export type CloseToastFunction = () => void;
export type ToastFunction = (
  message: ToastContent,
  options?: {
    duration?: number | false;
    position?: POSITION;
  },
) => CloseToastFunction;

export const DEFAULT_TOAST_DURATION = 6000;

const toastOptions: PluginOptions = {
  position: POSITION.TOP_CENTER,
  timeout: DEFAULT_TOAST_DURATION,
  transition: {
    enter: 'Vue-Toastification__bounce-enter-active',
    move: 'Vue-Toastification__bounce-move',
    leave: 'fade',
  },
  pauseOnHover: true,
  draggable: false,
  hideProgressBar: true,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: { iconClass: 'material-icons alert-icon', iconChildren: 'check_circle' },
      toastClassName: 'bg-success',
    },
    [TYPE.ERROR]: {
      icon: { iconClass: 'material-icons-outlined alert-icon', iconChildren: 'report' },
      toastClassName: 'bg-danger',
    },
    [TYPE.WARNING]: {
      icon: { iconClass: 'material-icons alert-icon', iconChildren: 'warning' },
      toastClassName: 'bg-warning',
    },
    [TYPE.INFO]: {
      icon: { iconClass: 'material-icons-outlined alert-icon', iconChildren: 'info' },
      toastClassName: 'bg-info',
    },
  },
};

// NOTE(@alexv): we install this plugin more than once into more than one app. if we
// create a toast interface each time we do this, we end up duplicating toasts.
// instead, create a single global toast interface for the whole runtime.
const toast = createToastInterface(toastOptions);

const makeToastFunction = (type: Exclude<TYPE, TYPE.DEFAULT>): ToastFunction =>
  function showToast(
    message,
    { duration = DEFAULT_TOAST_DURATION, position = POSITION.TOP_CENTER } = {},
  ) {
    const toastId: ToastID = toast[type](message, {
      position,
      timeout: duration,
    });
    return () => toast.dismiss(toastId);
  };

export const showSuccessToast: ToastFunction = makeToastFunction(TYPE.SUCCESS);
export const showErrorToast: ToastFunction = makeToastFunction(TYPE.ERROR);
export const showWarningToast: ToastFunction = makeToastFunction(TYPE.WARNING);
export const showInfoToast: ToastFunction = makeToastFunction(TYPE.INFO);
export const showCustomToast = (
  id: ToastID,
  { content, options }: { content: ToastContent; options?: ToastOptions },
) => {
  toast.update(id, { content, options }, true);
  return () => toast.dismiss(id);
};
export const clearAllToasts = toast.clear; // for integration testing

export { POSITION, TYPE };

const toastPlugin: Plugin = {
  install(Vue) {
    Vue.config.globalProperties.$showSuccessToast = showSuccessToast;
    Vue.config.globalProperties.$showErrorToast = showErrorToast;
    Vue.config.globalProperties.$showWarningToast = showWarningToast;
    Vue.config.globalProperties.$showInfoToast = showInfoToast;
  },
};

export default toastPlugin;
