<template>
  <div :id="name">
    <!-- teleport target -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * Marker component to indicate where alerts should be placed
 * in the layout. Use a <Portal :to="ALERT_PORTAL_NAME"> to render
 * alerts in place of this component.
 */
export default defineComponent({
  name: 'PortalOutlet',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
</script>
