import { ComponentPropsOptions } from 'vue';

/**
 * Wrap prop objects with this function to make typescript happy (and props non-optional when they
 * shouldn't be) when you're using props objects declared from outside the current component, or built
 * by spreading other props objects together.
 *
 * @param props A component's props declaration.
 * @see https://github.com/vuejs/core/issues/5703
 */
export const declareExternalProps = <T extends ComponentPropsOptions>(props: T) => props;
