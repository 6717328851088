import { Component } from 'vue';
import { useAsyncComponent } from './useAsyncComponent';

export function useAsyncSetup(setup: () => Promise<any>): (c: Component) => Component {
  return (WrappedComponent) =>
    useAsyncComponent(async () => {
      await setup();
      return WrappedComponent;
    });
}
