<template>
  <i
    class="material-icons"
    :class="{
      [`material-icons--${theme}`]: true,
      [`md-${size}`]: Boolean(size),
    }"
  >
    {{ name }}
  </i>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FULLY_COMPATIBLE } from '../utils/compat';
import { ICON_THEMES, ICON_SIZES } from '../constants';

export default defineComponent({
  name: 'BaseIcon',
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Icon name. Corresponds to Material Icon names.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Forces the font size of the icon to a specific value.
     */
    size: {
      type: [String, Number] as PropType<(typeof ICON_SIZES)[number]>,
      default: null,
      validator(value) {
        return ICON_SIZES.includes(Number(value) as any);
      },
    },
    /**
     * Controls the theme of the icon, whether the icon is outlined or filled.
     */
    theme: {
      type: String as PropType<(typeof ICON_THEMES)[number]>,
      default: 'filled',
      validator(value) {
        return ICON_THEMES.includes(value as any);
      },
    },
  },
});
</script>
