/* eslint-disable import/order, import/first */
import app from './app';
import router from './router';
import initSentry from './logging/initSentry';

initSentry(app, router);

import '@productiv/compass/bootstrap';
import { AxiosRequestConfig } from 'axios';
import { directives, compassPlugin } from '@productiv/compass';
import { plugin } from '@productiv/forms';
import Login from './views/Login.vue';
import routes from './router/routes';
import store from './store';
import api from './api';
import login from './login';
import analytics from './analytics';
import * as Environment from '@/utils/environment';

app
  .use(directives)
  .use(api, { store, baseUrl: Environment.API_BASE_URL })
  .use(login, { store, router, LoginViewComponent: Login })
  .use(analytics, {
    store,
    router,
    isAnalyticsEnabled: Environment.IS_ANALYTICS_ENABLED,
    additionalEventProperties: { product: 'appstore' },
    userpilotApiKey: Environment.USER_PILOT_API_KEY,
  })
  .use(routes, { store, router })
  .use(compassPlugin)
  .use(store)
  .use(router)
  .use(plugin, {
    fileUploadApiCaller: async (getConfig: () => AxiosRequestConfig) => {
      const response = await store.getters['api/authenticatedAxiosInstance'](getConfig());
      return response.data;
    },
  })
  .mount('#app');
