import { compact, every, isNil, merge } from 'lodash';
import { FormKitNode } from '@formkit/core';
import { DefaultConfigOptions } from '@formkit/vue';
import { ChoiceInputType, FormInput, FormInputType } from './forms.types';
import { SchemaExtensions, defineSchemaExtensions, isMultipleChoiceOption } from './schemaUtils';

type ValidationSchemaArg<T extends FormInput> = {
  schemaKey: keyof T;
  defaultValue?: any;
};

/**
 * Helper utility to convert properties from the forms schema into FormKit validation rules
 */
function generateRuleFromSchema<T extends FormInput>(
  ruleName: string,
  input: T,
  args: ValidationSchemaArg<T>[],
) {
  if (every(args, ({ schemaKey }) => isNil(input[schemaKey]))) {
    return undefined;
  }
  const rule: [string, ...any] = [ruleName];
  args.forEach(({ schemaKey, defaultValue }) => {
    const arg = input[schemaKey] ?? defaultValue;
    if (!isNil(arg)) {
      rule.push(arg);
    }
  });
  return rule;
}

/**
 * Conditionally adds the bootstrap `.is-invalid` class if the input
 * is invalid and has been submitted
 */
const getValidationAttrs = (section: string) => ({
  attrs: {
    class: {
      if: '$state.valid === false && $state.validationVisible',
      then: `$classes.${section} + " is-invalid"`,
      else: `$classes.${section}`,
    },
  },
});

/**
 * Extends the label schema to add a red asterisk next to the labels
 * of fields that are required.
 */
const attachRequiredAsterisk = (extensions: SchemaExtensions, node: FormKitNode) => {
  node.context!.fns.isRequired = () =>
    node.props.parsedRules.some((rule: { name: string }) => rule.name === 'required');

  const isMultipleChoice = isMultipleChoiceOption(node);
  const section = isMultipleChoice ? 'legend' : 'label';
  const children = [
    '$label',
    {
      if: '$fns.isRequired()',
      $el: 'span',
      attrs: { class: 'ml-1 text-danger' },
      children: ['*'],
    },
  ];
  if (typeof extensions[section] !== 'object') {
    extensions[section] = {};
  }
  if (!Array.isArray((extensions[section] as any).children)) {
    (extensions[section] as any).children = children;
  } else if (!(extensions[section] as any).children.__disable_required_plugin) {
    // this __disable_required_plugin flag is a naughty hack but I don't
    // really know a better way to prevent the backed field icon from
    // showing up alongside the required label icon
    (extensions[section] as any).children.push(children[1]);
  }
};

/**
 * Returns a function that will modify the node schema to set up validation-related features,
 * including the necessary styles to show validation messages and required asterisks.
 */
export const addNodeValidationSchema = defineSchemaExtensions({
  box: (extensions) => {
    extensions.fieldset = merge({}, extensions.fieldset, getValidationAttrs('fieldset')); // this element precedes the validation messages, so is-invalid is needed to make them show up
    extensions.input = merge({}, extensions.input, getValidationAttrs('input'));
  },
  text: (extensions) => {
    extensions.inner = merge({}, extensions.inner, getValidationAttrs('inner')); // this element precedes the validation messages, so is-invalid is needed to make them show up
    extensions.input = merge({}, extensions.input, getValidationAttrs('input'));
  },
  all: attachRequiredAsterisk,
});

export const globalValidationMessages: Exclude<
  DefaultConfigOptions['messages'],
  undefined
>[string] = {
  validation: {
    min({ name, args, node }) {
      return !isMultipleChoiceOption(node)
        ? `${name} must be greater than or equal to ${args[0]}`
        : `At least ${args[0]} must be selected`;
    },
    max({ node, name, args }) {
      return !isMultipleChoiceOption(node)
        ? `${name} must be less than or equal to ${args[0]}`
        : `No more than ${args[0]} may be selected`;
    },
  },
};

export default function generateValidation(formInput: FormInput) {
  const validators: ([string] | [string, ...any])[] = [];

  if (formInput.required) {
    validators.push(['required']);
  }

  switch (formInput.inputType) {
    case FormInputType.LongText:
    case FormInputType.ShortText: {
      const rule = generateRuleFromSchema('length', formInput, [
        { schemaKey: 'minLength', defaultValue: 0 },
        { schemaKey: 'maxLength' },
      ]);
      if (rule) {
        validators.push(rule);
      }
      break;
    }
    case FormInputType.Choice: {
      if (formInput.choiceType === ChoiceInputType.Checkbox) {
        validators.push(
          ...compact([
            generateRuleFromSchema('min', formInput, [{ schemaKey: 'minItems' }]),
            generateRuleFromSchema('max', formInput, [{ schemaKey: 'maxItems' }]),
          ]),
        );
      }
      break;
    }
    case FormInputType.Number:
    case FormInputType.Currency: {
      validators.push(['number']);
      validators.push(
        ...compact([
          generateRuleFromSchema('min', formInput, [{ schemaKey: 'minimum' }]),
          generateRuleFromSchema('max', formInput, [{ schemaKey: 'maximum' }]),
        ]),
      );
      break;
    }
  }

  return validators;
}
