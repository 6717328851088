import { FONT_AWESOME_ICON_THEMES } from '@productiv/compass/constants';

export enum PROCUREMENT_ROUTE_NAMES {
  HOME = 'procurement',
  TILE_CREATION = 'procurement-tile-creation',
  TILE_EDIT = 'tile-edit',
  FORM = 'form',
  MANAGE_TILES = 'manage-tiles',
}

export enum TILE_TYPES {
  REQUEST_ACCESS = 'requestAccess',
  NEW_SOFTWARE = 'newSoftware',
  CUSTOM_TILE = 'customTile',
}

export enum WORKFLOW_TYPES {
  SOFTWARE_INTAKE = 'SOFTWARE_INTAKE',
  INTAKE = 'INTAKE',
}

export enum TILE_DESTINATIONS {
  LINK = 'link',
  WORKFLOW = 'workflow',
  ROUTER_LINK = 'routerLink',
}

export interface DestinationLink {
  type: TILE_DESTINATIONS.LINK;
  url: string;
}

export interface DestinationWorkflow {
  type: TILE_DESTINATIONS.WORKFLOW;
  workflowId: string;
}

export interface TileIcon {
  name: string;
  theme: (typeof FONT_AWESOME_ICON_THEMES)[number];
}

export enum VISIBILITY_TYPES {
  USER = 'user',
  GROUP = 'group',
  TEAM = 'team',
}

export interface GroupCollaborator {
  type: VISIBILITY_TYPES.GROUP;
  id: string;
}

export interface TeamCollaborator {
  type: VISIBILITY_TYPES.TEAM;
  name: string;
}

interface IndividualCollaborator {
  type: VISIBILITY_TYPES.USER;
  email: string;
}

export type VisibilityCollaborator = GroupCollaborator | TeamCollaborator | IndividualCollaborator;

export interface ApiVisibilityUser extends IndividualCollaborator {
  firstName: string;
  lastName: string;
}

interface ApiVisibilityTeam extends TeamCollaborator {
  numUsers: number;
}

interface ApiVisibilityGroup extends GroupCollaborator {
  name: string;
}

export type ApiVisibilityCollaboratorRes =
  | ApiVisibilityUser
  | ApiVisibilityTeam
  | ApiVisibilityGroup;

export interface ApiTileType {
  tileType: TILE_TYPES;
  tileId: string;
  description: string;
  displayName: string;
  icon: TileIcon;
  destination?: DestinationWorkflow | DestinationLink;
  formId?: string;
  visibility?: ApiVisibilityCollaboratorRes[];
  disabled?: boolean;
}

export interface WorkflowDetailsRes {
  name: string;
  workflowId: string;
}

// Represents the payload that we send to the API on create/edit
export type ProcurementTilePayload = Omit<
  ApiTileType,
  'formId' | 'tileType' | 'tileId' | 'visibility'
> & { visibility?: VisibilityCollaborator[] };

export const COLLABORATOR_ICON_MAP = {
  [VISIBILITY_TYPES.GROUP]: 'user-group',
  [VISIBILITY_TYPES.TEAM]: 'users-rectangle',
  [VISIBILITY_TYPES.USER]: 'user-large',
};
