<template>
  <StatusProvider v-if="shouldRender" :loading="loading" :errors="errors" :retry="retry">
    <StatusSwitch>
      <template #loading>
        <slot name="loading">
          <Component
            :is="component"
            v-bind="attrs"
            class="status-indicator loading w-100"
            :class="overlay && 'loading-overlay loading'"
          >
            <BaseSpinner :size="size" />
          </Component>
        </slot>
      </template>

      <template #error v-if="$slots.error">
        <slot name="error" />
      </template>

      <Component :is="component" v-bind="attrs">
        <slot />
      </Component>
    </StatusSwitch>
  </StatusProvider>
</template>

<style scoped lang="scss">
.loading-overlay {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading {
    width: auto;
    font-size: 30px;
  }

  &.errors {
    bottom: 40px;
  }
}
</style>

<script lang="ts">
import { computed, PropType } from 'vue';
import { isEmpty, omit } from 'lodash';
import { FULLY_COMPATIBLE } from '../../utils/compat';
import BaseIcon from '../BaseIcon.vue';
import BaseSpinner from '../BaseSpinner.vue';
import { ICON_SIZES } from '../../constants';
import StatusProvider from './StatusProvider.vue';
import StatusSwitch from './StatusSwitch.vue';
import { AsyncStatus } from './statusContext';

export default {
  name: 'StatusIndicator',
  components: {
    BaseIcon,
    StatusProvider,
    StatusSwitch,
    BaseSpinner,
  },
  inheritAttrs: false,
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Controls whether or not the spinner is loading.
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * Errors to render if the content does not load. This is supposed to be a map of some errorId
     * to an Error object for historical reasons, but in practice the errorId is hardly ever used
     * and most of the times it is just `error`.
     */
    errors: {
      type: Object as PropType<AsyncStatus['errors']>,
      default: null,
    },
    /**
     * If provided, in case of an error, will render a "Retry" button
     * that calls the provided function when clicked.
     */
    retry: {
      type: Function as PropType<AsyncStatus['retry']>,
      default: null,
    },
    /**
     * The container element tag used to wrap the loading slot and default slot. Present for
     * historical (Vue 2) reasons but rarely changed. All non-prop attributes passed to this
     * component are bound to this element.
     */
    component: {
      type: String,
      default: 'div',
    },
    /**
     * Controls whether or not content can be rendered underneath the spinner.
     */
    overlay: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the size of the status indicator. Must be a size that maps
     * to a material icon size, not an arbitrary number.
     */
    size: {
      type: Number as PropType<(typeof ICON_SIZES)[number]>,
      default: 22,
    },
    /**
     * If true, only renders content if there is an error. Note that
     * `errors` must not be null for this prop to work correctly.
     */
    errorOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { attrs }) => {
    const shouldRender = computed(() => {
      const hasErrors = !isEmpty(props.errors);
      return !props.errorOnly || hasErrors;
    });

    return { shouldRender, attrs: omit(attrs, 'loaded') };
  },
};
</script>
