import Cookies from 'js-cookie';
import { COOKIE_DOMAIN } from '@/utils/environment';

function getCookieOptions() {
  const cookieOptions: Cookies.CookieAttributes = {
    secure: !window.location.host.startsWith('localhost'),
    expires: 365,
  };
  if (COOKIE_DOMAIN) {
    cookieOptions.domain = COOKIE_DOMAIN;
  }
  return cookieOptions;
}

export const cookieStorage = {
  get: (key: string) => {
    return Cookies.getJSON(key);
  },
  set: (key: string, value: any) => {
    Cookies.set(key, JSON.stringify(value), getCookieOptions());
  },
  remove: (key: string) => {
    Cookies.remove(key, getCookieOptions());
  },
};
