export enum ANALYTICS_EVENT {
  LOG_IN = 'Logged In',
  REFRESHED_TOKEN = 'Refreshed Token',
  LOG_OUT = 'Logged Out',
  SEARCH_SUCCESS = 'Search Completed',
  DASHBOARD_LINK_CLICKED = ' Productiv Dashboard Link Clicked',
  APP_WEBSITE_CLICKED = 'App Website Opened',
  APP_FILTER_SELECTED = 'App Filter Selected',
  REQUEST_NEW_APP = 'Request New App Link Clicked',
  APP_STORE_SLACK_CONTACT_LINK_CLICKED = 'App Store Slack Contact Link Clicked',
  LIST_VIEW_CHANGED = 'Changed AppCenter List View',
  APP_CUSTOM_RESOURCE_CLICKED = 'App Store Custom Resource Link Clicked',
  SUBMIT_ACS_APP_MODAL_CLICKED = 'Submit ACS App Request From Modal Clicked',
  SUBMIT_ACS_APP_PAGE_CLICKED = 'Submit ACS App Request From Page Clicked',
  REQUEST_SIMILAR_APP_MODAL_CLICKED = 'Request Similar ACS App From Modal Clicked',
  REQUEST_SIMILAR_APP_PAGE_CLICKED = 'Request Similar ACS App From Page Clicked',
  OPEN_SIMILAR_APPS_MODAL_CLICKED = 'Open Similar Apps Modal Clicked',
  CLOSE_SIMILAR_APPS_MODAL_CLICKED = 'Close Similar Apps Modal Clicked',
  CANCEL_CREATE_TILE_CLICKED = 'Cancel Create Tile Clicked',
  SUBMIT_CREATE_TILE_CLICKED = 'Submit Create Tile Clicked',
  SUBMIT_EDIT_TILE_CLICKED = 'Edit Tile Clicked',
  CANCEL_EDIT_TILE_CLICKED = 'Cancel Edit Tile Clicked',
  DELETE_TILE_CLICKED = 'Delete Tile Clicked',
  SUBMIT_NEW_SOFTWARE_CLICKED = 'Submit New Software in Search New Apps Bar Clicked',
  CANCEL_PROCURE_INTAKE_FORM_CLICKED = 'Cancel Procure Intake Form Clicked',
  SUBMIT_PROCURE_INTAKE_FORM_CLICKED = 'Submit Procure Intake Form Clicked',
}

export enum ANALYTICS_PAGE {
  APP_PAGE = 'App',
  APP_CAPABILITY_PAGE = 'App Capability',
  APP_FUNCTIONAL_CATEGORY_PAGE = 'App Functional Category',
  APP_VENDOR_PAGE = 'App Vendor',
  MY_APPS_PAGE = 'My Apps',
  APP_STORE_HOME_PAGE = 'App Store Home',
  TEAM_APPS_PAGE = 'Used By My Team',
  FEATURED_APPS_PAGE = 'Featured Apps',
  PRODUCTIV_HOME = 'Productiv Home',
  MANAGE_TILES = 'Manage Tiles',
  CREATE_TILE = 'Create Tile',
  EDIT_TILE = 'Edit Tile',
  MY_APPROVALS = 'My Approvals',
  MY_REQUESTS = 'My Requests',
  ALL_REQUESTS = 'All Requests',
  SEARCH_NEW_APPS = 'Search New Apps',
  REQUEST_APP_MODAL = 'Request App and Similar Apps Modal',
  REQUEST_APP_PAGE = 'Request App',
  PROCURE_INTAKE_FORM = 'Procure Intake Form',
}
