import { computed, onBeforeMount } from 'vue';
import createLoaderModule, { getStatus } from './utils/createLoaderModule';
import useStoreModule from './utils/useStoreModule';

interface CustomerResponse {
  asOfDate: string;
  customerName: string;
  aphIntakeProcurementCentral: boolean;
  newProcurementTaskLink: boolean;
  intakeCustomTiles: boolean;
  aphProcurementCollaboratorVisibility: boolean;
  requestOverviewPage: boolean;
}

const customerModule = createLoaderModule<CustomerResponse>((axios) =>
  axios({
    method: 'get',
    url: 'usage/customer',
  }),
);

export default function useCustomerModule() {
  const { state, dispatch } = useStoreModule('customer', customerModule);

  onBeforeMount(() => {
    dispatch('loadOnce');
  });

  return {
    status: getStatus(state, dispatch),
    customerName: computed(() => state.loadedData?.customerName),
    defaultAsOfDate: computed(() => state.loadedData?.asOfDate),
    // NOTE(@alexv): placeholder for customer-customizable name.
    // Probably will not be the final home for this.
    appCenterName: computed(() => 'Productiv Home'),
    enabledProcurementCentral: computed(() => state.loadedData?.aphIntakeProcurementCentral),
    intakeCustomTilesEnabled: computed(() => state.loadedData?.intakeCustomTiles ?? false),
    useNewTaskLink: computed(() => state.loadedData?.newProcurementTaskLink ?? false),
    aphProcurementCollaboratorVisibility: computed(
      () => state.loadedData?.aphProcurementCollaboratorVisibility ?? false,
    ),
  };
}
