import * as Sentry from '@sentry/vue';
import { LAST_USER_LOCALSTORAGE_KEY } from '@/login/constants';

export default function beforeSend(event: Sentry.Event) {
  if (!localStorage.getItem(LAST_USER_LOCALSTORAGE_KEY)) {
    return null;
  }

  return event;
}
