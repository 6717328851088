<template>
  <BasePageBanner
    v-if="isAssumedRole"
    theme="orange"
    class="impersonation-banner"
    @click="revertToOriginal"
  >
    <template #message>
      READ-ONLY: Impersonating
      <b class="mx-1">{{ targetUserName }} ({{ targetUserEmail }})</b>
      on customer
      <b class="mx-1">{{ targetCustomerId }}</b>
    </template>
    <template #button-text>
      <span>Revert to original user</span>
    </template>
  </BasePageBanner>
</template>

<script>
import { mapGetters } from 'vuex';
import BasePageBanner from './BasePageBanner.vue';

export default {
  name: 'ImpersonationBanner',
  components: {
    BasePageBanner,
  },
  computed: {
    ...mapGetters({
      isAssumedRole: 'auth/isAssumedRole',
      targetCustomerId: 'auth/customerId',
      targetUserEmail: 'auth/userId',
      targetUserName: 'auth/friendlyName',
    }),
  },
  methods: {
    revertToOriginal() {
      this.$router.replace({
        name: 'revert-role',
      });
    },
  },
};
</script>
