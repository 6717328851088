import { onMounted, onUnmounted } from 'vue';
import { CloseToastFunction, showCustomToast, POSITION } from '@productiv/compass/plugins/toasts';
import BackToTopButton from '@/components/BackToTopButton.vue';

export default function useBackToTopButton() {
  let dismissToast: CloseToastFunction | undefined;

  function clearToast() {
    if (dismissToast === undefined) {
      return;
    }
    dismissToast();
    dismissToast = undefined;
  }

  function showBackToTopToast(event: Event) {
    const scrollTop = (event.target as Document)?.scrollingElement?.scrollTop || 0;
    if (scrollTop < 100) {
      clearToast();
      return;
    }
    dismissToast = showCustomToast('backToTop', {
      content: {
        component: BackToTopButton,
        listeners: {
          click: () => {
            document.scrollingElement?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setTimeout(clearToast, scrollTop * 0.5);
          },
        },
      },
      options: {
        timeout: false,
        position: POSITION.BOTTOM_RIGHT,
        toastClassName: 'bg-transparent shadow-none mb-1 w-auto',
        closeButtonClassName: 'd-none',
      },
    });
  }

  onMounted(() => {
    document.addEventListener('scroll', showBackToTopToast);
  });
  onUnmounted(() => {
    document.removeEventListener('scroll', showBackToTopToast);
    clearToast();
  });
}
