import { ActionTree, MutationTree } from 'vuex';
import { Userpilot } from 'userpilot';
import { cookieStorage } from '../utils/cookies';
import { ANONYMOUS_ID_COOKIE_KEY } from './constants';
import { makeAnonymousUuid } from '@/utils/uuid';

interface AnalyticsModuleState {
  analyticsEnabled: boolean;
  userpilotEnabled: boolean;
  additionalEventProperties: any;
}

const createState: () => AnalyticsModuleState = () => ({
  analyticsEnabled: false,
  userpilotEnabled: false,
  additionalEventProperties: {},
});

const mutations: MutationTree<AnalyticsModuleState> = {
  enableAnalytics(state, { userpilotEnabled }: { userpilotEnabled: boolean }) {
    state.analyticsEnabled = true;
    state.userpilotEnabled = userpilotEnabled;
  },
  setAdditionalEventProperties(state, { additionalEventProperties }) {
    state.additionalEventProperties = {
      ...state.additionalEventProperties,
      ...additionalEventProperties,
    };
  },
};

function getAnonymousId() {
  let anonymousId = cookieStorage.get(ANONYMOUS_ID_COOKIE_KEY);
  if (anonymousId) {
    return anonymousId;
  }
  anonymousId = makeAnonymousUuid();
  cookieStorage.set(ANONYMOUS_ID_COOKIE_KEY, anonymousId);
  return cookieStorage.get(ANONYMOUS_ID_COOKIE_KEY);
}

const actions: ActionTree<AnalyticsModuleState, {}> = {
  trackEvent({ state, dispatch }, { event, properties }) {
    if (state.analyticsEnabled) {
      dispatch(
        'api/sendBeacon',
        {
          api: 'gin/ZXZlbnQ',
          params: {
            event,
            properties: { ...properties, ...state.additionalEventProperties },
            anonymousId: getAnonymousId(),
          },
        },
        { root: true },
      );
    }
  },
  trackPage({ state, dispatch }, { name, properties }) {
    if (state.analyticsEnabled) {
      dispatch(
        'api/sendBeacon',
        {
          api: 'gin/cGFnZQ',
          params: {
            name,
            properties: { ...properties, ...state.additionalEventProperties },
            anonymousId: getAnonymousId(),
          },
        },
        { root: true },
      );
    }
  },
  identifyUser({ state, dispatch }, { userId, traits, customerId }) {
    if (state.analyticsEnabled) {
      dispatch(
        'api/sendBeacon',
        { api: 'gin/aWRlbnRpZnk', params: { userId, traits, anonymousId: getAnonymousId() } },
        { root: true },
      );
      if (state.userpilotEnabled) {
        Userpilot.identify(userId, {
          ...traits,
          company: {
            id: customerId,
          },
        });
      }
    }
  },
  clearAnonymousIdCookies() {
    cookieStorage.remove(ANONYMOUS_ID_COOKIE_KEY);
  },
};

export default {
  namespaced: true,
  state: createState,
  actions,
  mutations,
};
