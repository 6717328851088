import { getCurrentInstance } from 'vue';
import { ToastFunction } from '../plugins/toasts';

export default function useToasts() {
  const proxy = getCurrentInstance()?.proxy as any;
  return {
    showSuccessToast: proxy.$showSuccessToast?.bind(proxy) as ToastFunction,
    showWarningToast: proxy.$showWarningToast?.bind(proxy) as ToastFunction,
    showErrorToast: proxy.$showErrorToast?.bind(proxy) as ToastFunction,
    showInfoToast: proxy.$showInfoToast?.bind(proxy) as ToastFunction,
  };
}
