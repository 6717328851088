export const APP_CENTER_STATUS_REASONS = {
  APP_CENTER_NOT_ENABLED_FOR_CUSTOMER: 'AppCenter package has not been enabled',
  APP_CENTER_NOT_OPEN_FOR_ALL: 'AppCenter is not enabled for all users',
  APP_CENTER_SET_UP_IN_PROGRESS: 'No ticketing connector connected for AppCenter',
};

export const APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_TITLE_MAPPING = {
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_ENABLED_FOR_CUSTOMER]: 'Productiv Home not available',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_OPEN_FOR_ALL]: 'Productiv Home Setup in Progress',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_SET_UP_IN_PROGRESS]: 'Productiv Home Setup in Progress',
};

export const APP_CENTER_STATUS_REASON_TO_USER_MESSAGE_MAPPING = {
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_ENABLED_FOR_CUSTOMER]:
    'Productiv Home is currently not available for your company.',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_OPEN_FOR_ALL]:
    'Your admin is currently in the process of setting up Productiv Home. Please check back later or contact your admin for further questions.',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_SET_UP_IN_PROGRESS]:
    'Your admin is currently in the process of setting up Productiv Home. Please check back later or contact your admin for further questions.',
};

export const APP_CENTER_STATUS_REASON_TO_ADMIN_MESSAGE_MAPPING = {
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_ENABLED_FOR_CUSTOMER]:
    'If you would like to enable this feature, contact Productiv. ',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_NOT_OPEN_FOR_ALL]:
    'If you are ready to deploy Productiv Home to employees, contact Productiv to enable it for non-admin accounts. ',
  [APP_CENTER_STATUS_REASONS.APP_CENTER_SET_UP_IN_PROGRESS]:
    'If you are ready to deploy Productiv Home to employees, contact Productiv to enable it for non-admin accounts. ',
};
