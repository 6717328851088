<template>
  <img :src="appIcon" :width="width" :height="height" :class="inactive && 'inactive'" />
</template>

<style lang="scss" scoped>
.inactive {
  opacity: 0.5;
}
</style>

<script lang="ts">
import { DEFAULT_APP_ICON } from '../static/defaultAppIcon';
import { FULLY_COMPATIBLE } from '../utils/compat';

export default {
  name: 'AppIcon',
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * URL of the image to render as an icon. If not provided,
     * a default app icon will be rendered instead.
     */
    iconSrc: {
      type: String,
      default: null,
    },
    /**
     * Icon width.
     */
    width: {
      type: [Number, String],
      default: 25,
    },
    /**
     * Icon height.
     */
    height: {
      type: [Number, String],
      default: 25,
    },
    /**
     * Controls whether or not the icon is inactive. Inactive icons
     * are rendered at reduced opacity to make them appear "grayed out"
     * for use with disabled states.
     */
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    appIcon() {
      return this.iconSrc || DEFAULT_APP_ICON;
    },
  },
};
</script>
