<template>
  <span :class="className">
    <template v-for="(segment, idx) of segments">
      <span v-if="segment.highlight && !disable" :key="idx" class="font-weight-bold highlight">
        {{ segment.text }}
      </span>
      <template v-else>{{ segment.text }}</template>
    </template>
  </span>
</template>

<style lang="scss" scoped>
.highlight {
  background-color: palette-color('orange', 'light');
}
</style>

<script lang="ts">
import { FULLY_COMPATIBLE } from '../../utils/compat';

export default {
  name: 'OmniSearchTextMatch',
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * The search input text.
     */
    search: {
      type: String,
      default: '',
    },
    /**
     * The search result.
     */
    result: {
      type: String,
      default: '',
    },
    /**
     * Controls whether or not the highlights for the matching text appear.
     */
    disable: {
      type: Boolean,
      default: false,
    },
    /**
     * The class applied to the search result.
     */
    className: {
      type: String,
      default: null,
    },
  },
  computed: {
    segments() {
      if (!this.search.length) {
        return [{ text: this.result, highlight: false }];
      }

      const index = this.findIndex();
      if (index === -1) {
        return [{ text: this.result, highlight: false }];
      }

      return [
        { start: 0, end: index },
        { start: index, end: index + this.search.length },
        { start: index + this.search.length, end: this.result.length },
      ]
        .filter((seg) => seg.end - seg.start) // filter out empty segments
        .map(({ start, end }) => ({
          text: this.result.substring(start, end),
          highlight: start === index,
        }));
    },
  },
  methods: {
    findIndex() {
      const lowerCaseResult = this.result.toLowerCase();
      const lowerCaseSearch = this.search.toLowerCase();

      let index = lowerCaseResult.indexOf(lowerCaseSearch);
      if (index === -1 || index === 0) {
        return index;
      }

      const tokenSplits = [' '];
      while (!tokenSplits.includes(lowerCaseResult[index - 1])) {
        index = lowerCaseResult.indexOf(lowerCaseSearch, index + 1);
        if (index === -1) {
          return -1;
        }
      }
      return index;
    },
  },
};
</script>
