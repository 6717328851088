<template>
  <HeroSidebarLayout id="faqs" align-sidebar="left" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HeroSidebarLayout, { HeroSidebarOutlet } from '@/layouts/HeroSidebarLayout.vue';

export const FAQLayout = HeroSidebarOutlet;

export default defineComponent({
  name: 'FAQLayout',
  components: {
    HeroSidebarLayout,
  },
});
</script>
