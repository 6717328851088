import {
  FormKitNode,
  FormKitSchemaCondition,
  FormKitSchemaNode,
  FormKitTypeDefinition,
} from '@formkit/core';
import { clone, compact } from 'lodash';
import { MaybeRef, unref } from 'vue';

export type SchemaExtensions = Record<string, Partial<FormKitSchemaNode> | FormKitSchemaCondition>;
export type SchemaExtender = (exts: SchemaExtensions, node: FormKitNode) => void;
/**
 * Defines a map of node function to a set of schema extensions which should be applied.
 */
export type SchemaExtensionMap = Partial<{
  /**
   * Extends the schema of a box family node, such as radios or checkboxes.
   */
  box: SchemaExtender;
  /**
   * Extends the schema of a text-family node (including textareas).
   */
  text: SchemaExtender;
  /**
   * Uncondititionally extends the schema of any node.
   */
  all: SchemaExtender;
}>;

export function wrapSchema(
  definition: FormKitTypeDefinition | undefined,
  wrapper: (extensions: SchemaExtensions) => SchemaExtensions,
) {
  const inputDefinition = clone(definition)!;
  if (typeof inputDefinition?.schema !== 'function') {
    return definition;
  }
  const originalSchema = inputDefinition.schema;
  const higherOrderSchema = (extensions: SchemaExtensions) => {
    return originalSchema(wrapper(extensions));
  };
  inputDefinition.schema = higherOrderSchema;
  return inputDefinition;
}

/**
 * Returns true if the node is a multiple choice node.
 */
export function isMultipleChoiceOption(node: FormKitNode) {
  return (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;
}

/**
 * Creates a function which can be used to extend the schema of a node conditionally
 * based on its function. The resulting function should typically be called inside of the
 * callback to `wrapSchema`.
 */
export function defineSchemaExtensions(extensionMap: SchemaExtensionMap) {
  return (node: FormKitNode, extensions: SchemaExtensions) => {
    if (node.props.family === 'box') {
      extensionMap.box?.(extensions, node);
    } else if (node.props.family === 'text' || node.props.type === 'textarea') {
      extensionMap.text?.(extensions, node);
    }
    extensionMap.all?.(extensions, node);
  };
}

export function getInputId(inputId: MaybeRef<string>, formName?: MaybeRef<string | null>) {
  return compact([unref(formName), unref(inputId)]).join('-');
}
