<template>
  <div id="home" class="d-flex flex-column" :style="theme">
    <StatusProvider v-bind="status">
      <div class="sticky-top primary-border-bottom">
        <ImpersonationBanner name="impersonationBanner" />
        <RouterView name="header" />
      </div>
      <RouterView name="layout" />
      <DelayedRouterView name="content" />

      <RouterView />

      <PortalOutlet name="modal" />
    </StatusProvider>
  </div>
</template>

<style lang="scss" scoped>
#home {
  min-height: 100%;
}
.primary-border-bottom {
  border-bottom: 10px solid $brand-primary;
  background-color: $white;
}
</style>

<script lang="ts">
import { CSSProperties, Component, defineComponent, computed, unref } from 'vue';
import StatusProvider from '@productiv/compass/components/Status/StatusProvider.vue';
import { RouterView } from 'vue-router';
import PortalOutlet from '@/components/PortalOutlet.vue';
import ImpersonationBanner from '@/components/ImpersonationBanner.vue';
import useCustomerModule from '@/store/useCustomerModule';
import { useAsyncSetup } from '@/utils/useAsyncSetup';
import useWhiteLabelingThemes from '@/compositions/useWhiteLabelingThemes';

const delayMount = (c: Component) => useAsyncSetup(() => Promise.resolve())(c);

export default defineComponent({
  name: 'Home',
  components: {
    ImpersonationBanner,
    PortalOutlet,
    StatusProvider,
    // NOTE(@alexv): we need to delay the mounting of the content router-view
    // to give time for the portal outlets to mount. if the layout view and the
    // content view mount at the same time, Vue complains that the teleports have nowhere
    // to send their content to and the app totally crashes.
    DelayedRouterView: delayMount(RouterView),
  },
  setup() {
    const { status } = useCustomerModule();
    const { linkColor, textColor, primaryColor } = useWhiteLabelingThemes();

    const theme = computed(() => {
      return {
        '--primary-color': unref(primaryColor).defaultColor,
        '--primary-hover': unref(primaryColor).hoverColor,
        '--primary-pressed': unref(primaryColor).pressedColor,
        '--link-color': unref(linkColor),
        '--text-color': unref(textColor),
      } as CSSProperties;
    });

    return {
      theme,
      linkColor,
      textColor,
      primaryColor,
      status,
    };
  },
});
</script>
