import { InjectionKey } from 'vue';
import { AxiosRequestConfig } from 'axios';
import { invert, mapValues, toLower } from 'lodash';

export const CUSTOM_FORM_API_CALLER_KEY = Symbol('CUSTOM_FORM_API_CALLER_KEY') as InjectionKey<
  (getConfig: () => AxiosRequestConfig) => any
>;
// One time export from SUPPORTED_MIME_TYPES in packages/aws/src/s3-utils.js on the BE
// Pls also update there if new MIME type needs to be added
export const MIME_TYPES = {
  PDF: 'application/pdf',
  TXT: 'text/plain',
  CSV: 'text/csv',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  JSON: 'application/json',
};

export const SUPPORTED_MIME_TYPES = mapValues(invert(MIME_TYPES), (ext, mimeType) => ({
  value: mimeType,
  label: `*.${toLower(ext)}`,
}));
