<template>
  <div class="w-100 p-5 m-5 d-flex justify-content-center lead">
    Signing out
    <div class="mx-2">
      <StatusIndicator loading />
    </div>
  </div>
</template>

<script>
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserLogout',
  components: {
    StatusIndicator,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  mounted() {
    if (this.isLoggedIn) {
      this.logOut();
    } else {
      this.$router.replace({ name: 'user-login' });
    }
  },
  methods: {
    ...mapActions({ logOut: 'auth/logout' }),
  },
};
</script>
