export const CATALOG_MODULE_NAME = 'catalog';

export enum CATALOG_ROUTE_NAMES {
  ALL_APPS = 'catalog-all-apps',
  CAPABILITY_APPS = 'catalog-capability-apps',
  FUNCTIONAL_CATEGORY_APPS = 'catalog-functional-category-apps',
  VENDOR_APPS = 'catalog-vendor-apps',
  TEAM_APPS = 'catalog-team-apps',
  FEATURED_APPS = 'featured-apps',
  MY_APPS = 'catalog-my-apps',
  VIEW_APP = 'catalog-view-app',
  ALL_CATEGORIES = 'all-categories',
  REDIRECT = 'catalog-redirect',
}

export enum RECOMMENDATION_TYPES {
  USED_BY_TEAM = 'usedInTeam',
  FEATURED = 'ITRecommended',
}
