<template>
  <span v-if="name" :key="name" ref="containerRef">
    <i
      ref="iconRef"
      class="fontawesome-icons"
      :class="{
        [`fa-${name}`]: true,
        [`fa-${theme}`]: true,
      }"
      :style="
        size && {
          width: `${size}px`,
          fontSize: `${size}px`,
        }
      "
    />
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { FULLY_COMPATIBLE } from '../utils/compat';
import { FONT_AWESOME_ICON_THEMES, ICON_SIZES } from '../constants';

export default defineComponent({
  name: 'BaseSvgIcon',
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * renders icon with name and prefix fa-[name]
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * size will be inherited as font size
     */
    size: {
      type: Number as PropType<(typeof ICON_SIZES)[number]>,
      default: null,
      validator(value) {
        return ICON_SIZES.includes(value as any);
      },
    },
    /**
     *  renders icon with themes and prefix fa-[theme]
     */
    theme: {
      type: String as PropType<(typeof FONT_AWESOME_ICON_THEMES)[number]>,
      default: 'regular',
      validator(value) {
        return FONT_AWESOME_ICON_THEMES.includes(value as any);
      },
    },
  },
});
</script>
