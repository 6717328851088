import { computed, onBeforeMount } from 'vue';
import createLoaderModule, { getStatus } from '@/store/utils/createLoaderModule';
import useStoreModule from '@/store/utils/useStoreModule';

export interface FAQ {
  question: string;
  answer: string;
}

const faqsModule = createLoaderModule<FAQ[]>((axios) =>
  axios({
    method: 'get',
    url: 'appstore/faq',
  }),
);

export default function useFaqs() {
  const { state, dispatch } = useStoreModule('faqs', faqsModule);

  onBeforeMount(() => {
    dispatch('loadOnce', null);
  });

  return {
    status: getStatus(state, dispatch),
    faqs: computed(() => state.loadedData),
    hasFaqs: computed(() => state.loadedData?.length),
  };
}
