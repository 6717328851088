<template>
  <div
    v-bind="classes"
    class="search-wrapper d-flex align-items-center"
    :class="`search-${inputSize}`"
    role="presentation"
  >
    <div v-if="icon" class="ml-2 d-flex">
      <slot name="icon">
        <BaseIcon class="search-icon" :name="icon" :size="iconSize" />
      </slot>
    </div>

    <input
      v-bind="inputAttrs"
      ref="input"
      v-focus="autofocus"
      type="text"
      role="search"
      class="w-100"
      :disabled="readonly || ($attrs.disabled as boolean)"
      :class="[inputSize === 'md' ? 'form-control' : `form-control-${inputSize}`]"
      :style="{ minWidth: inputMinWidth }"
      :spellcheck="false"
      :value="modelValue === null ? localInputValue : modelValue"
      @keydown.esc.stop="
        () => {
          blur();
          clearInput();
        }
      "
      @input="($event) => emitInput(($event.target as HTMLInputElement).value)"
    />

    <template v-if="hasInput">
      <BaseIcon
        v-if="!hideClear"
        class="clear-icon cursor-pointer mr-2"
        role="button"
        name="cancel"
        theme="outlined"
        :size="iconSize"
        @click="clearInput"
      />
    </template>
    <template v-else-if="$slots.rightIcon">
      <div class="mr-1">
        <slot name="rightIcon" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@mixin placeholder($color: inherit) {
  $placeholder-prefixes: 'placeholder', '-webkit-input-placeholder', '-moz-placeholder',
    '-ms-input-placeholder';

  @each $placeholder in $placeholder-prefixes {
    &::#{$placeholder} {
      transition: color 0.1s ease-in;
      color: $color;
    }
  }
}

.search-wrapper {
  border-radius: 0.25rem;
  transition: all 0.1s ease-in;
  box-sizing: border-box;
  border: $border-width solid palette-color('gray', 'light');
  background-color: $white;
  color: $gray;
  .search-icon {
    pointer-events: none;
  }

  input {
    border: none;
    outline: none;
    background: none;
    @include placeholder($gray);
  }

  &.search-md {
    input {
      // The search-wrapper adds a border but the whole container should be
      // the correct height
      height: $input-height - (2 * $border-width);
    }
  }

  &:focus-within {
    color: $gray;
    background-color: $white;
    border-color: $blue;
  }
}
</style>

<script lang="ts">
import { isString, pick, omit } from 'lodash';
import { computed, defineComponent, HTMLAttributes, PropType } from 'vue';
import { FULLY_COMPATIBLE } from '../utils/compat';
import { STANDARD_SIZES } from '../constants';
import focus from '../directives/focus';
import BaseIcon from './BaseIcon.vue';

const SIZE_TO_ICON_SIZE = {
  sm: 16,
  md: 16,
  lg: 24,
} as const;

export default defineComponent({
  name: 'BaseSearchInput',
  components: {
    BaseIcon,
  },
  compatConfig: FULLY_COMPATIBLE,
  directives: { focus },
  inheritAttrs: false,
  props: {
    /**
     * Value of the input field. Supports two-way binding with v-model.
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * Controls whether or not the input is automatically focused when the component mounts.
     * Changing this after the component mounts has no effect.
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls whether or not a button to clear the current input is provided.
     */
    hideClear: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * Controls the size of the search input.
     */
    inputSize: {
      type: String as PropType<string>,
      default: 'md',
      validator(value) {
        return isString(value) && STANDARD_SIZES.includes(value as any);
      },
    },
    /**
     * Name of the icon to be rendered. Allows you to override the default icon.
     * Corresponds to &lt;BaseIcon&gt; names.
     */
    icon: {
      // if false, hides the icon
      type: [String, Boolean] as PropType<string | false | undefined>,
      default: 'search',
    },
    /**
     * Controls whether or not the search input is readonly.
     */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Alias of update:modelValue, for use in scenarios where
     * two-way binding is not desired.
     */
    'update:modelValue',
    /**
     * Emitted when the search input has a value.
     */
    'input',
    /**
     * Emitted when the clear button is clicked.
     */
    'reset',
  ],
  setup(props) {
    const iconSize = computed(() => SIZE_TO_ICON_SIZE[props.inputSize]);

    return {
      iconSize,
    };
  },
  data() {
    return {
      // needed to prevent a bug with consumers that don't provide a value prop
      // where the first input is "eaten"
      localInputValue: null,
      hasInput: false,
    };
  },
  computed: {
    classes() {
      return pick(this.$attrs, 'class', 'style') as HTMLAttributes;
    },
    inputAttrs() {
      return omit(this.$attrs, 'class', 'style');
    },
    inputMinWidth() {
      const { placeholder } = this.inputAttrs as { placeholder?: string };
      if (placeholder && placeholder.length > 0) {
        // exact text sizing is hard but this works well
        return `${placeholder.length}ch`;
      }

      return '75px';
    },
  },
  watch: {
    value(value) {
      this.hasInput = Boolean(value);
      this.localInputValue = value;
    },
  },
  methods: {
    /**
     * This function is part of the external api of the component so that
     * unref(BaseSearchInput).focus() works similar to an input element for
     * imperative focus management.
     */
    focus() {
      const inputRef = this.$refs.input as HTMLElement;
      inputRef.focus();
    },
    /**
     * This function is part of the external api of the component so that
     * unref(BaseSearchInput).blur() works similar to an input element for
     * imperative focus management.
     */
    blur() {
      const inputRef = this.$refs.input as HTMLElement;
      inputRef.blur();
    },
    clearInput() {
      const inputRef = this.$refs.input as HTMLInputElement;
      inputRef.value = '';
      this.emitInput('');
      this.$emit('reset');
    },
    emitInput(value) {
      this.hasInput = Boolean(value);
      this.localInputValue = value;
      this.$emit('input', value);
      this.$emit('update:modelValue', value);
    },
  },
});
</script>
