import {
  DeepReadonly,
  Ref,
  InjectionKey,
  provide,
  inject,
  ref,
  computed,
  reactive,
  onMounted,
  onUnmounted,
  ComputedRef,
  unref,
} from 'vue';
import { omit, assign, some, compact, map, isEmpty } from 'lodash';

export const ANONYMOUS_STATUS = Symbol('anonymous-status');

export interface AsyncStatus {
  loading: boolean;
  loaded?: boolean;
  error?: Error;
  errors?: Record<string, Error>;
  retry?: () => void;
}

export type StatusRef = Ref<AsyncStatus>;

interface ContextualStatus {
  name: string | symbol;
  status: AsyncStatus; // Should be a reactive object
  readonly errorHasMultipleOutlets: DeepReadonly<Ref<boolean>>;
  trackRef: () => void;
  freeRef: () => void;
}

type StatusContext = Record<string, ContextualStatus>;

const STATUS_CONTEXT_INJECTION_KEY: InjectionKey<StatusContext> = Symbol('status-context');

function mergeStatuses(statuses: (AsyncStatus | undefined)[]): AsyncStatus {
  return reactive({
    loading: computed(() => some(compact(statuses), 'loading')),
    errors: computed(() => assign({}, ...compact(map(statuses, 'errors')))),
    retry: computed(() => {
      if (!some(map(statuses, 'retry'))) {
        return undefined;
      }
      return async () => {
        const retries = statuses.map((status) => {
          if (!status?.errors) {
            return Promise.resolve();
          }
          return status.retry?.() ?? Promise.resolve();
        });
        await Promise.all(retries);
      };
    }),
  });
}

/**
 * Provides a named status to all children of this component, which can be accessed with useStatusContext.
 * If there is already a status with this name higher in the tree, the status exposed downward in the tree
 * will be a merger of the already-provided status and the passed-in status.
 *
 * @param name The name to expose this status under. If you want a "generic" status, use ANONYMOUS_STATUS
 * @param status The status to provide.
 */
export const useProvideStatusContext = (name: string | symbol, status: AsyncStatus) => {
  const parentContext = inject(STATUS_CONTEXT_INJECTION_KEY, {});
  const parentContextForName = parentContext[name as string]; // Symbol indexers aren't allowed in TS versions before 4.4, but this is valid.

  const childrenRefCount = ref(0);

  // NOTE(@andrew.gies)
  //  If an errored status has multiple switches watching it, the status provider is responsible for
  //  handling the error by rendering a toast, and the switches should just stick to the loading state.
  const errorHasMultipleOutlets = computed(() => {
    if (isEmpty(status.errors) && isEmpty(parentContextForName?.status.errors)) {
      // No errors at our level or above us in the tree...nothing to render.
      return false;
    }
    // Below here, _something_ has an error to deal with. Potentially multiple errors.
    if (unref(parentContextForName?.errorHasMultipleOutlets)) {
      // If a parent provider is itself providing an error and there are multiple
      // switches in the subtree, then that provider is already aware that it needs to
      // render a toast and any switches that see this error are absolved of responsibility.
      // FIXME(@andrew.gies)
      //  Right now, error merging is super janky and there's a decent chance that multi-subscribed status
      //  switches will render the wrong error if there are multiple statuses with errors (e.g. if multiple
      //  errors overwrite a single key in the errors object) . Given the current UX, this is fine, because
      //  if there are any multi-subscribed statuses that have errors, at least one provider will pop a toast
      //  and request the user reload the page, which will retry all failed requests anyway. If we start to
      //  get smarter about error handling at some point (e.g. letting the user click the toast to retry the
      //  request without reloading), we'll need to be careful about how they are merged and which particular
      //  errors we render in multi-subscribed status switches in those cases.
      //  At some point, we could get fancy and say "if this provider's status has a _different_ error, we
      //  can prioritize that error and render it inline", but that's not worth doing until we have a reliable
      //  error merging plan in place.
      return true;
    }
    if (isEmpty(status.errors)) {
      return false;
    }
    return unref(childrenRefCount) > 1;
  });

  const statusToProvide: StatusContext = {
    ...parentContext,
    [name]: {
      name,
      status: mergeStatuses([parentContextForName?.status, status]),
      errorHasMultipleOutlets,
      trackRef: () => {
        childrenRefCount.value++;
        parentContextForName?.trackRef();
      },
      freeRef: () => {
        if (childrenRefCount.value <= 0) {
          throw new Error('Too many calls to free()!');
        }
        childrenRefCount.value--;
        parentContextForName?.freeRef();
      },
    },
  };

  onUnmounted(() => {
    if (childrenRefCount.value !== 0) {
      throw new Error(
        'Status provider unmounted but there is one or more children still using it! There is a leak!',
      );
    }
  });

  provide(STATUS_CONTEXT_INJECTION_KEY, statusToProvide);

  return { errorHasMultipleOutlets };
};

/**
 * Fetches a reactive status from all providers above this component in the tree with the provided
 * name(s). If multiple names are provided, statuses from all matching providers will be merged.
 *
 * @param names Names to use. If you want just a "generic" status, use ANONYMOUS_STATUS.
 */
export const useStatusContext = (
  names: (string | symbol)[],
): { status: AsyncStatus; errorHasMultipleOutlets: ComputedRef<boolean> } => {
  if (names.length === 0) {
    // If we're not subscribed to anything, there's definitely not going to be any statuses to work with.
    throw new Error('useStatusContext was not given any status names to subscribe to');
  }

  const parentContext = inject(STATUS_CONTEXT_INJECTION_KEY, {});

  // We allow only non-matching contexts to travel deeper through the tree, any contexts
  // that we consume are opaque to children of this component.
  const contextToExpose = omit(parentContext, names);
  provide<Partial<StatusContext>>(STATUS_CONTEXT_INJECTION_KEY, contextToExpose);

  const foundParentEntries = compact(map(names, (name) => parentContext[name as string]));

  if (foundParentEntries.length === 0) {
    // There are NO statuses above us in the tree for us to work with, so we
    // just need to early-return (we'll assume there's nothing loading so we
    // don't brick the page), as there's no point in continuing.
    return {
      status: { loading: false, errors: {}, retry: undefined },
      errorHasMultipleOutlets: computed(() => false),
    };
  }

  onMounted(() => {
    foundParentEntries.forEach((parentEntry) => parentEntry.trackRef());
  });

  onUnmounted(() => {
    foundParentEntries.forEach((parentEntry) => parentEntry.freeRef());
  });

  const status = mergeStatuses(map(foundParentEntries, 'status'));
  const errorHasMultipleOutlets = computed(() => {
    return some(foundParentEntries, (parent) => unref(parent.errorHasMultipleOutlets));
  });

  return {
    status,
    errorHasMultipleOutlets,
  };
};
