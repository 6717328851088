export function base64UrlEncode(data: object) {
  return btoa(JSON.stringify(data) || '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '.');
}

export function base64UrlDecode(str: string) {
  return str && JSON.parse(atob(str.replace(/-/g, '+').replace(/_/g, '/').replace(/\./g, '=')));
}
