import { Directive, nextTick } from 'vue';

const MAX_FOCUS_ATTEMPT_TICKS = 10;

const focusElement = async (el: HTMLElement) => {
  let successful = false;
  let attempts = 0;

  do {
    el.focus();
    successful = document.activeElement === el;
    attempts++;
    await nextTick();
  } while (!successful && attempts < MAX_FOCUS_ATTEMPT_TICKS);

  // TODO(@andrew.gies) Warn to Sentry if this is still unsuccessful after MAX_FOCUS_ATTEMPT_TICKS
};

const FocusDirective: Directive = {
  mounted: function (el, binding) {
    // default to focusing by treating undefined as truthy
    if (binding.value === undefined || binding.value) {
      focusElement(el);
    }
  },
  updated: function (el, binding) {
    if (binding.value && binding.value !== binding.oldValue) {
      focusElement(el);
    }
  },
};

export default FocusDirective;
