import { computed } from 'vue';
import createLoaderModule from './utils/createLoaderModule';
import useStoreModule from './utils/useStoreModule';

export interface AppCenterStatus {
  enabled: boolean;
  reason?: string | undefined;
}

interface AppCenterLogo {
  attachmentId: string;
  url: string;
}

interface ButtonTheme {
  defaultColor: string;
  hoverColor: string;
  pressedColor: string;
}

export interface AppCenterPalette {
  linkTheme: string;
  textColor: string;
  primaryTheme: ButtonTheme;
}

export interface ProductivHomeTitleCustomization {
  homeBannerTitle?: string;
  homeBannerSubTitle?: string;
  requestAccessBannerTitle?: string;
}

interface AppCenterTheme {
  logo: AppCenterLogo;
  theme: AppCenterPalette;
  titles?: ProductivHomeTitleCustomization;
}

interface AppCenterStatusState {
  appCenterStatus: AppCenterStatus | null;
  isUsedByMyTeamRecommendationEnabled?: boolean;
  appCenterTheme: AppCenterTheme;
}

const appCenterStatusModule = createLoaderModule<AppCenterStatusState>((axios) =>
  axios({
    method: 'get',
    url: 'appstore/app-center',
  }),
);

export default function useAppCenterStatusModule() {
  const { state, dispatch } = useStoreModule('appCenterStatus', appCenterStatusModule);

  const loadAppCenterStatus = async () => {
    await dispatch('loadOnce');
  };
  return {
    isAppCenterEnabled: computed(() => state.loadedData?.appCenterStatus?.enabled),
    appCenterNotEnabledReason: computed(() => state.loadedData?.appCenterStatus?.reason),
    isUsedByMyTeamRecommendationEnabled: computed(
      () => state.loadedData?.isUsedByMyTeamRecommendationEnabled,
    ),
    appCenterTheme: computed(() => state.loadedData?.appCenterTheme),
    loadAppCenterStatus,
  };
}
