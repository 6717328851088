<template>
  <div class="w-100 p-5 m-5 d-flex justify-content-center lead">
    Assuming Role
    <div class="mx-2">
      <StatusIndicator loading />
    </div>
  </div>
</template>

<script>
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import { mapActions } from 'vuex';
import * as Environment from '@/utils/environment';

export default {
  name: 'AssumeRole',
  components: {
    StatusIndicator,
  },
  async mounted() {
    if (!this.$route.query.target_cid) {
      await this.$router.replace({
        name: 'home',
      });
    }
    try {
      await this.assumeRole({
        targetCid: this.$route.query.target_cid,
        targetUser: this.$route.query.target_user,
      });

      // hard refresh to home page
      window.location.replace(Environment.BASE_URL);
    } catch (err) {
      // if assume role fails (likely from not enough permissions)
      // go back to home page
      this.$router.replace({ name: 'home' });
    }
  },
  methods: {
    ...mapActions({ assumeRole: 'auth/assumeRole' }),
  },
};
</script>
