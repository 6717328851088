<template>
  <form
    v-if="!attemptAutoLogin"
    class="text-center login-form"
    autocomplete="off"
    @submit.prevent="login"
  >
    <div class="form-group mb-5">
      <label for="emailinput" class="sr-only">Email address</label>
      <input
        id="emailinput"
        v-model="loginId"
        type="email"
        class="form-control username"
        placeholder="Email address"
      />
    </div>
    <BaseButton
      class="sign-in-button"
      type="submit"
      theme="primary"
      :loading="isLoggingIn"
      :disabled="!formValid"
    >
      Sign in
    </BaseButton>
  </form>
  <div v-else class="text-center">
    Signing in {{ autoLoginEmail ? `as ${autoLoginEmail}` : '' }}
    <StatusIndicator :loading="true" class="d-inline" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import lodashDebounce from 'lodash/debounce';
import BaseButton from '@productiv/compass/components/BaseButton.vue';
import StatusIndicator from '@productiv/compass/components/Status/StatusIndicator.vue';
import { emailValidator } from '../utils/validators';
import useAuthModule from './useAuthModule';

export default defineComponent({
  name: 'LoginForm',
  components: {
    BaseButton,
    StatusIndicator,
  },
  setup() {
    let stagingPromise: Promise<void>;
    const authModule = useAuthModule();
    const router = useRouter();
    const route = useRoute();
    const updateAuthClient = (stagingLoginEmail: string | null, idp: string | null) => {
      stagingPromise = authModule.dispatch('stageLoginId', { stagingLoginEmail, idp });
      return stagingPromise;
    };
    const debouncedUpdateAuthClient = lodashDebounce(updateAuthClient, 300);
    const loginId = ref(authModule.getters.userId);
    const isLoggingIn = ref(false);

    const autoLoginEmail = computed((): string | null => {
      const emailQueryParam: string = route.query.email as string;
      if (Boolean(emailQueryParam) && !emailValidator(emailQueryParam)) {
        return emailQueryParam;
      }
      return null;
    });
    const autoLoginIdp = computed((): string | null => {
      const idpQueryParam: string = route.query.idp as string;
      return idpQueryParam ?? null;
    });
    const attemptAutoLogin = computed((): boolean => {
      return Boolean(unref(autoLoginEmail) || unref(autoLoginIdp));
    });

    const initiateLogin = async (query: object) => {
      isLoggingIn.value = true;
      try {
        // if authClient is not being updated already, refer to email from state or lastLoginEmail
        stagingPromise
          ? await stagingPromise
          : await authModule.dispatch('stageLoginId', { stagingLoginEmail: loginId.value });
        // will never resolve if successful
        await authModule.dispatch('initiateLogin', { query });
      } catch (e) {
        // TODO: report error
        console.error(e);
        router.replace({
          query: {
            error: 'There was an error logging you in. Please refresh the page and try again.',
          },
        });
      } finally {
        isLoggingIn.value = false;
      }
    };

    const login = () => initiateLogin(route.query);

    onMounted(() => {
      if (authModule.getters.isLoggedIn) {
        router.replace('/');
      } else if (unref(attemptAutoLogin)) {
        // Auto login if email is present as a query parameter
        updateAuthClient(unref(autoLoginEmail), unref(autoLoginIdp));
        login();
      }
    });

    return {
      loginId,
      isLoggingIn,
      updateAuthClient,
      debouncedUpdateAuthClient,
      login,
      attemptAutoLogin,
      autoLoginEmail,
    };
  },
  computed: {
    formValid(): boolean {
      return this.isEmailValid;
    },
    isEmailValid(): boolean {
      return Boolean(this.loginId) && !emailValidator(this.loginId);
    },
  },
  watch: {
    loginId: function (newLoginEmail) {
      if (this.isEmailValid) {
        this.debouncedUpdateAuthClient(newLoginEmail, null);
      }
    },
  },
});
</script>
