import mapValues from 'lodash/mapValues';
import { computed, reactive } from 'vue';
import { Module, Store, useStore } from 'vuex';

/**
 * Creates a "scoped view" over a store module. The module's state, getters, mutations, and actions
 * are available via .state, .getters, .commit(), and .dispatch(), but without the prefix of `moduleName/`.
 */
export function createScopedStore<TModuleState = any>(
  store: Store<any>,
  moduleName: string,
  storeModule: Module<TModuleState, any>,
) {
  return {
    get state(): TModuleState {
      return reactive(store.state[moduleName]);
    },
    /**
     * @deprecated Prefer using composition-private modules and directly
     * accessing state for better type-safety
     */
    get getters() {
      return reactive<{ [x: string]: any }>(
        mapValues(storeModule.getters, (_, getterName) =>
          computed(() => store.getters[`${moduleName}/${getterName}`]),
        ),
      );
    },
    commit(mutationName: string, payload?: any) {
      return store.commit(`${moduleName}/${mutationName}`, payload);
    },
    dispatch(actionName: string, payload?: any) {
      return store.dispatch(`${moduleName}/${actionName}`, payload);
    },
  };
}

/**
 * Installs a store module, if needed, and returns a scoped view over
 * that module's state, getters, and actions.
 */
export default function useStoreModule<TModuleState = any>(
  moduleName: string,
  storeModule: Module<TModuleState, any>,
) {
  if (!storeModule.namespaced) {
    throw new Error('Store modules must be namespaced');
  }

  const store = useStore();
  if (!store.hasModule(moduleName)) {
    store.registerModule(moduleName, storeModule);
  }

  return createScopedStore(store, moduleName, storeModule);
}
