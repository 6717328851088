import lodashIsFunction from 'lodash/isFunction';
import { Ref, unref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import lodashGet from 'lodash/get';

export default function useScrollToRef(componentRefs: { [refName: string]: Ref<any> }) {
  const route = useRoute();

  function scrollToRef(scrollToRefName: string) {
    let elementOrComponent = unref(componentRefs[scrollToRefName]);
    if (!elementOrComponent) {
      return;
    }

    if (Array.isArray(elementOrComponent)) {
      elementOrComponent = elementOrComponent[0];
    }

    const domElement = elementOrComponent.$el ? elementOrComponent.$el : elementOrComponent;
    if (domElement) {
      if (!lodashIsFunction(domElement.scrollIntoView)) {
        // TODO (@ziqi): Report Error Here
        return;
      }

      domElement.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  watchEffect(
    () => {
      const scrollToRefName = (lodashGet(route, 'hash') || '').substring(1);
      scrollToRef(scrollToRefName);
    },
    { flush: 'post' },
  );

  return scrollToRef;
}
