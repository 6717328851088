<template>
  <OmniSearchLocalDataset
    v-bind="datasetProps"
    :items="items"
    @select="(selection) => $emit('select', selection)"
    @create="(text) => $emit('create', text)"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </OmniSearchLocalDataset>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, onMounted, ref } from 'vue';
import { omit } from 'lodash';
import { FULLY_COMPATIBLE } from '../../utils/compat';
import OmniSearchLocalDataset from './OmniSearchLocalDataset.vue';
import {
  OmniSearchItem,
  OmniSearchLocalDatasetProps,
  OmniSearchDatasetEvents,
} from './omniSearch.types';
import { useConsumeOmniSearchContext } from './useOmniSearchContext';

export default defineComponent({
  name: 'OmniSearchFetchedDataset',
  components: { OmniSearchLocalDataset },
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Function that fetches the list of items to search over locally.
     */
    fetchItems: {
      type: Function as PropType<() => Promise<OmniSearchItem[]>>,
      required: true,
    },
    /**
     * If true, only fetches the items when the OmniSearch is first focused.
     * If false, fetches the items as soon as this dataset mounts.
     * Warning: Unreactive to changes after this component is created.
     */
    lazy: {
      type: Boolean,
      default: false,
    },
    ...omit(OmniSearchLocalDatasetProps, 'items'),
  },
  emits: {
    ...OmniSearchDatasetEvents,
  },
  setup: (props) => {
    const datasetProps = computed(() => omit(props, ['fetchItems', 'lazy']));
    const context = useConsumeOmniSearchContext();

    const items = ref<OmniSearchItem[]>([]);
    const loadItems = async () => {
      context.emit('loading:start', { id: props.id });
      items.value = await props.fetchItems();
      context.emit('loading:end', { id: props.id });
    };

    if (props.lazy) {
      const triggerLoad = () => {
        context.off('focus', triggerLoad);
        loadItems();
      };
      context.on('focus', triggerLoad);
    } else {
      onMounted(loadItems);
    }

    return {
      datasetProps,
      items,
    };
  },
});
</script>
