import qs from 'qs';
import * as Sentry from '@sentry/browser';
import { Ref, ref, unref, watch } from 'vue';
import { LocationQueryRaw, useRouter } from 'vue-router';

import lodashIsEqual from 'lodash/isEqual';

type ParsedQueryValue = boolean | string | number | object;
export function useQuery<TReturn extends ParsedQueryValue>(
  paramName: string,
  array: true,
): Ref<TReturn[] | undefined>;
export function useQuery<TReturn extends ParsedQueryValue>(
  paramName: string,
  array?: false,
): Ref<TReturn | undefined>;

export function useQuery<TReturn extends ParsedQueryValue>(
  paramName: string,
  array = false,
): Ref<TReturn | TReturn[] | undefined> {
  const query: Ref<TReturn | TReturn[] | undefined> = ref(undefined);

  const router = useRouter();
  const route = router?.currentRoute;

  async function updateQueryParameters(queryParams: {
    [paramName: string]: TReturn | TReturn[] | undefined;
  }) {
    if (!router) {
      return;
    }
    const currentRouteQuery = unref(route).query;
    const mergedQuery = {
      ...currentRouteQuery,
      ...queryParams,
    };
    if (lodashIsEqual(mergedQuery, currentRouteQuery)) {
      return;
    }
    try {
      await router.replace({
        query: mergedQuery as LocationQueryRaw,
      });
    } catch (error) {
      // TODO (@ziqi) switch with error capturing system when properly set up
      Sentry.captureException(error);
    }
  }

  function coerce(value: string | object): TReturn {
    if (value === null) {
      return '' as TReturn;
    }
    if (typeof value === 'object') {
      return value as TReturn;
    }
    if (value === 'true') {
      return true as TReturn;
    } else if (value === 'false') {
      return false as TReturn;
    } else if (!isNaN(Number(value))) {
      return Number(value) as TReturn;
    } else {
      return value as TReturn;
    }
  }

  function setRef(queryValue: unknown) {
    if (!queryValue) {
      return query;
    }
    if (array === true) {
      query.value = (Array.isArray(queryValue) ? queryValue : [queryValue])
        .filter((v): v is string => v !== null)
        .map(coerce);
    } else {
      query.value = coerce(Array.isArray(queryValue) ? queryValue[0]! : queryValue);
    }
  }

  watch(query, (newQuery: TReturn | TReturn[] | undefined) => {
    const queryParams = { [paramName]: newQuery };
    updateQueryParameters(queryParams);
  });

  watch(route, (newRoute) => {
    const newQueryValue = newRoute?.query[paramName];
    setRef(newQueryValue);
  });

  // NOTE(@alexv): parse the initial value without vue-router so that this function can
  // be used to get a query value from places where vue-router is not yet initialized or not injectable
  const queryValue = qs.parse(window.location.search, { ignoreQueryPrefix: true })[paramName];
  setRef(queryValue);

  return query;
}
