export const BUTTON_THEMES = [
  'primary',
  'secondary',
  'tertiary',
  'link',
  'danger',
  'red',
  'blue',
  'orange',
  'green',
  'dropdown',
  'gray',
  'light-gray',
] as const;
export const STANDARD_SIZES = ['sm', 'md', 'lg'] as const;
export const ICON_THEMES = ['outlined', 'filled'] as const;
export const ICON_SIZES = [12, 14, 16, 18, 20, 22, 24, 32, 36, 48, 60, 96, 128] as const;
export const FONT_AWESOME_ICON_THEMES = ['solid', 'regular', 'kit'] as const;
