import { FormKitNode } from '@formkit/core';
import { createInput } from '@formkit/vue';
import { ref } from 'vue';
import { isNil } from 'lodash';
import { wrapSchema } from '../schemaUtils';

export function makeCurrencyFormatter(currencyCode: string) {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
}

function currency(node: FormKitNode) {
  node.on('created', () => {
    const getCurrencyCode = (cc: string) =>
      makeCurrencyFormatter(cc)
        .formatToParts(0)
        .find(({ type }) => type === 'currency')?.value;
    node.context!.fns.getCurrencyCode = getCurrencyCode;

    node.props.definition = wrapSchema(node.props.definition, (extensions) => {
      extensions.prefix = {
        $el: 'div',
        attrs: {
          class: 'input-group-prepend',
        },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'input-group-text',
            },
            children: ['$fns.getCurrencyCode($currencyCode)'],
          },
        ],
      };
      return extensions;
    });

    const isFocused = ref(false);
    const blur = node.context!.handlers.blur;
    node.context!.focused = isFocused;

    node.context!.fns.makeValue = (v) => (!isNil(v) ? Number(v).toFixed(2) : v);

    node.context!.handlers.DOMInput = (evt: Event) => {
      isFocused.value = true;
      const target = evt.target as HTMLInputElement;
      const rawValue = target.value;

      const sanitizedValue = rawValue
        .replace(
          /[^\d]/g,
          (function makeReplacer() {
            let seenDot = false;
            // this function is run on all matches (every non-digit character).
            // it replaces all except the first `.` character with nothing.
            return (match: any) => (match === '.' && !seenDot ? ((seenDot = true), match) : '');
          })(),
        )
        // if there are more than 2 digits after the decimal place, this shifts the tenth place to the ones place
        .replace(/\.(\d)(\d{2}\d*)/, '$1.$2');

      // ensure that invalid inputs don't appear in the input box
      if (sanitizedValue !== rawValue) {
        target.value = sanitizedValue;
      }
      node.input(sanitizedValue);
    };

    node.context!.handlers.onBlur = () => {
      isFocused.value = false;
      blur();
    };
  });
}

const currencyInputSchema = {
  $el: 'input',
  bind: '$attrs',
  attrs: {
    id: '$id',
    disabled: '$disabled',
    class: '$classes.input',
    onInput: '$handlers.DOMInput',
    onBlur: '$handlers.onBlur',
    // NOTE(@alexv): this works pretty well to display the formatted value by
    // default and on blur while still allowing fluid editing, but there's a
    // minor issue with values of the form `XXXX.0X` - when backspacing, it will
    // clear both decimal digits instead of just the last one.
    value: { if: '$focused', then: '$_value', else: '$fns.makeValue($_value)' },
  },
};

export default createInput(currencyInputSchema, {
  type: 'input',
  forceTypeProp: 'currency',
  family: 'text',
  props: ['currencyCode'],
  features: [currency],
});
