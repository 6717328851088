import { FormKitTypeDefinition } from '@formkit/core';
import { generateClasses } from '@formkit/themes';

export default {
  type: 'group',
  schema: [
    {
      $formkit: 'text',
      label: '$label',
      id: '$id',
      bind: '$attrs',
      disabled: '$disabled',
      name: 'vendorName',
      validation: [['required']],
      validationMessages: { required: 'Vendor name is required' },
      placeholder: 'Name',
      classes: generateClasses({
        global: {
          outer: '$remove:mb-5 mb-2',
          inner: 'rounded-sm overflow-hidden border-0',
          input: 'bg-white opacity-1 ',
        },
      }),
    },
    {
      $formkit: 'text',
      bind: '$attrs',
      disabled: '$disabled',
      name: 'websiteUrl',
      help: '$help',
      validation: [['required']],
      validationMessages: { required: 'Vendor website is required' },
      placeholder: 'Website',
      classes: {
        input: 'bg-white opacity-1',
      },
    },
  ],
} as FormKitTypeDefinition;
