import { Plugin } from 'vue';
import { isNil, set } from 'lodash';
import { plugin, defaultConfig, DefaultConfigOptions } from '@formkit/vue';
import { generateClasses } from '@formkit/themes';
import { FormKitNode } from '@formkit/core';
import { addNodeValidationSchema, globalValidationMessages } from './validation';
import application from './inputs/application';
import currency from './inputs/currency';
import vendor from './inputs/vendor';
import { SchemaExtensions, defineSchemaExtensions, wrapSchema } from './schemaUtils';
import { CUSTOM_FORM_API_CALLER_KEY } from './constants';
import lookup from './inputs/lookup';

/**
 * Plugin function which modifies node schemas globally to be
 * more compatible with bootstrap styling.
 *
 * Primarily based on https://formkit.link/de8b22382c17d3d1d411a7b317367843 as
 * well as insight taken from the formkit source code (especially in regards to
 * the various expressions).  There are other ways to achieve similar results
 * (eg, using `formkit export`: https://formkit.com/guides/export-and-restructure-inputs)
 * but given that the changes made here are relatively global and apply to
 * multiple different kinds of inputs, it seemed more appropriate to use a plugin.
 */
const bootstrapPlugin = (node: FormKitNode) => {
  /**
   * Removes the wrapping `<ul>` from the validation messages and makes them into divs
   * so that they can be styled with bootstrap's validation message styles
   */
  const unwrapMessages = (extensions: SchemaExtensions) => {
    extensions.messages = { $el: null };
    extensions.message = { $el: 'div' };
  };

  /**
   * Creates a function which reformats input HTML to remove certain nesting
   * wrappers in order to make it easier to apply bootstrap classes to the
   * resulting schema (particularly w/r/t validation)
   */
  const simplifyNodeSchema = defineSchemaExtensions({
    text: (extensions) => {
      extensions.wrapper = { $el: null };
    },
    box: (extensions) => {
      extensions.decorator = { $el: null };
      extensions.wrapper = { $el: null };
      extensions.inner = { $el: null };
      extensions.label = { $el: 'label', attrs: { for: '$option.attrs.id' } };
    },
    all: (extensions) => {
      unwrapMessages(extensions);
      set(extensions, 'help.attrs.style', 'white-space: pre-wrap !important');
      extensions.outer = {
        attrs: { 'data-testid': 'form-field', 'data-fieldname': '$id' },
      };
    },
  });

  node.on('created', () => {
    node.props.definition = wrapSchema(node.props.definition, (extensions) => {
      simplifyNodeSchema(node, extensions);
      addNodeValidationSchema(node, extensions);
      return extensions;
    });
  });
};

/**
 * Modifies numeric inputs to emit their values as numbers, as well as capping
 * at 15 digits - safely before the input exceeds MAX_SAFE_INTEGER and starts
 * overflowing and rounding in bizarre ways.
 */
const convertNumberPlugin = (node: FormKitNode) => {
  if (node.props.type !== 'number' && node.props.type !== 'currency') {
    return;
  }

  const MAX_DIGITS = 15; // '9'.repeat(MAX_DIGITS) < Number.MAX_SAFE_INTEGER === true

  const castNumber = (value: any, next: any) =>
    value === '' || isNil(value) ? undefined : next(Number(value));
  node.hook.input(castNumber);

  node.on('created', () => {
    // DOMInput is the default onInput handler
    const DOMInput = node.context!.handlers.DOMInput;
    node.context!.handlers.DOMInput = (evt: Event) => {
      const el = evt.target as HTMLInputElement;
      if (el.value.replace(/[^\d]/, '').length > MAX_DIGITS) {
        // undo this input
        el.value = el.value.slice(0, MAX_DIGITS);
      }

      DOMInput(evt);
    };
  });
};

/**
 * A little plugin that automatically scrolls to the first error.
 * Source: https://formkit.link/d93ad9a1a25a570f0116855401076ee7
 **/
function scrollToErrorsPlugin(node: FormKitNode) {
  if (node.props.type !== 'form') {
    return false;
  }

  function scrollTo(n: FormKitNode) {
    const el = document.getElementById(n.props.id!);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function scrollToErrors() {
    node.walk((child) => {
      // Check if this child has errors
      if (child.ledger.value('blocking') || child.ledger.value('errors')) {
        // We found an input with validation errors
        scrollTo(child);
        // Stop searching
        return false;
      }
    }, true);
  }

  const onSubmitInvalid = node.props.onSubmitInvalid;
  node.props.onSubmitInvalid = () => {
    onSubmitInvalid?.(node);
    scrollToErrors();
  };
  node.on('unsettled:errors', scrollToErrors);
}

const CHOICE_STYLES = {
  legend: '$reset data-label text-wrap',
  options: 'list-unstyled mb-0',
  option: '$reset custom-control',
  input: '$reset custom-control-input',
  label: '$reset custom-control-label',
  help: 'mt-n2 mb-2',
};

const config: DefaultConfigOptions = {
  plugins: [bootstrapPlugin, convertNumberPlugin, scrollToErrorsPlugin],
  inputs: {
    application,
    vendor,
    currency,
    lookup,
  },
  iconLoader: (icon) => {
    const [iconName, iconTheme = 'solid'] = icon.split(':');

    // NOTE(@alexv): replaced and processed by FA javascript. See component docs for BaseSvgIcon
    return `<i class="fa-${iconName} fa-${iconTheme}" />`;
  },
  messages: {
    en: globalValidationMessages,
  },
  config: {
    sectionsSchema: {},
    classes: generateClasses({
      global: {
        outer: '$reset form-group mb-5',
        inner: '$reset input-group',
        prefix: '$reset input-group-prepend',
        suffix: '$reset input-group-append',
        input: '$reset form-control',
        label: '$reset form-label data-label text-wrap',
        help: '$reset form-text text-muted text-wrap',
        messages: 'list-unstyled',
        message: 'invalid-feedback',
      },
      'family:box': CHOICE_STYLES,
      radio: {
        option: `custom-radio`,
      },
      checkbox: {
        option: `custom-checkbox`,
      },
      range: {
        input: '$reset form-control-range',
      },
      select: {
        input: '$reset custom-select',
      },
      submit: {
        wrapper: 'text-right',
        outer: '$reset',
        input: '$reset btn btn-primary',
      },
      file: {
        inner: 'd-block',
        input: '$reset d-none',
        fileList: 'list-unstyled mb-1 w-fit',
        fileName: 'mr-1',
        fileItem: 'd-flex align-items-center',
        fileItemIcon: 'text-primary mr-1',
        noFiles: '$reset d-none',
      },
    }),
  },
};

export default {
  install(app, { fileUploadApiCaller }) {
    app.use(plugin, defaultConfig(config));
    app.provide(CUSTOM_FORM_API_CALLER_KEY, fileUploadApiCaller);
  },
} as Plugin;
