import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import qs from 'qs';
import $ from 'jquery';
import { attempt, compact, get, head, isError, noop, omit, pickBy } from 'lodash';
import * as Sentry from '@sentry/browser';
import { AuthClientBase, AUTH_PROVIDER } from './AuthClients';
import * as AuthApi from './AuthApi';
import { base64UrlDecode, base64UrlEncode } from './base64';
import {
  AUTH_COOKIE_KEY,
  REFRESH_TOKEN_LOCALSTORAGE_KEY,
  CLAIMS_DOMAIN,
  ANALYTICS_CW_METRICS,
  AUTH_TOKEN_TYPE,
  TOKEN_TYPE_CLAIM,
  EMPLOYEE_TO_USER_DOMAIN,
  EMPLOYEE_TO_CUSTOMER_DOMAIN,
  LAST_USER_LOCALSTORAGE_KEY,
} from './constants';
import USER_PERMISSION from './userPermissions';
import { ProductivJwtClaims, UserInfo, ResourceStatement } from './jwt';
import { CallbackParameters, UnreportedLoginError } from './AuthClients/AuthClientBase';
import { ANALYTICS_EVENT } from '@/constants/analytics';
import { cookieStorage } from '@/utils/cookies';
import * as Environment from '@/utils/environment';

export interface AuthCookie {
  source: AUTH_PROVIDER | null;
  clientConfig: AuthClientBaseClientConfig | null;
  userId: string | null;
  identityProvider: string | null;
}

interface ImpersonationStateInfo {
  originalUser: string;
  assumeType: string;
}

interface AuthModuleState {
  authClient: AuthClientBase | null;
  lastLoginEmail: string | null;
  loginErrorMessage: string | null;
  refreshToken: string | null;
  userInfo: {
    name: string;
    givenName: string;
    familyName: string;
    email: string;
    claims: Omit<ProductivJwtClaims, 'perms' | 'resourcePerms'>;
  } | null;
  userPermissions: { [cid: string]: USER_PERMISSION[] } | null;
  resourcePermissions: { [cid: string]: ResourceStatement[] } | null;
  impersonationInfo: ImpersonationStateInfo | null;
  identityProvider: string | null;
  returnUrl: string | null;
  returnQueryParameters: { [k: string]: string } | null;
}

/**
 * Records shape of the "state" object returned to us via Oauth providers
 */
interface OauthState<TAuthProvider extends AUTH_PROVIDER> {
  loginSource: TAuthProvider;
  clientConfig: AuthClientBaseClientConfig;
  identityProvider: string | null;
  lastLoginEmail: string;
  branch?: string;
  [queryKey: string]: unknown;
}

function cleanObject(obj: object) {
  return pickBy(obj, (v) => v !== undefined && v !== null && v !== '');
}

function encodeState<T extends AUTH_PROVIDER>(state: OauthState<T>): string {
  return base64UrlEncode(cleanObject(state));
}

function decodeState(encodedState: string): OauthState<AUTH_PROVIDER> {
  const stateDecoded = base64UrlDecode(encodedState);
  if (Object.values(AUTH_PROVIDER).includes(stateDecoded.loginSource)) {
    return {
      loginSource: stateDecoded.loginSource,
      clientConfig: stateDecoded.clientConfig as AuthClientBaseClientConfig,
      identityProvider: stateDecoded.identityProvider,
      lastLoginEmail: stateDecoded.lastLoginEmail,
      ...omit(stateDecoded, ['loginSource', 'clientConfig', 'identityProvider', 'lastLoginEmail']),
    };
  }
  throw new Error(
    `encodedState did not have a valid loginSource. Login source was: ${stateDecoded.loginSource}`,
  );
}

function captureAuthFailure(e: Error, operation: string) {
  if (Environment.ENVIRONMENT_NAME === '__LOCAL__') {
    console.error(e);
    return;
  }

  Sentry.captureException(e, (scope) => {
    scope.setLevel('info');
    scope.setTag('Auth operation', operation);
    scope.setFingerprint(['AUTH_FAIL']);
    return scope;
  });
}

/*
 * If userInfo object passed corresponds to token for an impersonating user,
 * then return originalUser (email) and assumeType (e2c/e2u), otherwise return null
 */
function getImpersonationInfoFromUserInfo(userInfo: UserInfo): ImpersonationStateInfo | null {
  const tokenType = userInfo[TOKEN_TYPE_CLAIM];
  let originalUserInfo;
  if (tokenType === AUTH_TOKEN_TYPE.EMPLOYEE_TO_CUSTOMER) {
    originalUserInfo = userInfo[EMPLOYEE_TO_CUSTOMER_DOMAIN];
  } else if (tokenType === AUTH_TOKEN_TYPE.EMPLOYEE_TO_USER) {
    originalUserInfo = userInfo[EMPLOYEE_TO_USER_DOMAIN];
  }
  if (originalUserInfo) {
    const originalUser = originalUserInfo.email;
    return { originalUser, assumeType: tokenType! };
  }
  return null;
}

const createState: () => AuthModuleState = () => ({
  authClient: null,
  lastLoginEmail: null,
  loginErrorMessage: null,
  refreshToken: null,
  userInfo: null,
  userPermissions: null,
  resourcePermissions: null,
  impersonationInfo: null,
  identityProvider: null,
  returnUrl: null,
  returnQueryParameters: null,
});

const mutations: MutationTree<AuthModuleState> = {
  setLastLogin(state, { lastLoginEmail }: { lastLoginEmail: string }) {
    state.lastLoginEmail = lastLoginEmail;
  },
  setAuthClient(
    state,
    {
      authClient,
      identityProvider,
      stagingLoginEmail,
    }: { authClient: AuthClientBase; identityProvider?: string; stagingLoginEmail?: string },
  ) {
    state.lastLoginEmail = stagingLoginEmail ?? state.lastLoginEmail ?? null;
    state.authClient = authClient;
    state.identityProvider = identityProvider ?? null;
  },
  setRefreshToken(state, { refreshToken }: { refreshToken: string | null }) {
    state.refreshToken = refreshToken;
  },
  setUserSession(state, { userInfo }: { userInfo: UserInfo }) {
    state.userInfo = {
      name: userInfo.name,
      givenName: userInfo.givenName,
      familyName: userInfo.familyName,
      email: userInfo.email,
      claims: omit(userInfo[CLAIMS_DOMAIN], ['perms', 'resourcePerms']),
    };
    const impersonationInfo = getImpersonationInfoFromUserInfo(userInfo);
    state.impersonationInfo = impersonationInfo;
    state.lastLoginEmail = impersonationInfo?.originalUser ?? userInfo.email;
    state.userPermissions = userInfo[CLAIMS_DOMAIN].perms;
    state.resourcePermissions = userInfo[CLAIMS_DOMAIN].resourcePerms;
  },
  clearUserSession(state) {
    state.userInfo = null;
    state.userPermissions = null;
    state.refreshToken = null;
    state.impersonationInfo = null;
  },
  setLoginError(state, { errorMessage }: { errorMessage: string }) {
    state.loginErrorMessage = errorMessage;
  },
  clearLoginError(state) {
    state.loginErrorMessage = null;
  },
  setReturnUrl(state, { queryParameters }: { queryParameters: { [k: string]: string } }) {
    state.returnUrl = queryParameters.next;
    state.returnQueryParameters = omit(queryParameters, ['next']);
  },
};

const actions: ActionTree<AuthModuleState, {}> = {
  persistAuthTokens({ state }) {
    const cookie: AuthCookie = {
      source: state.authClient?.source ?? null,
      clientConfig: (state.authClient?.getClientConfig() as any) ?? null,
      userId: state.lastLoginEmail,
      identityProvider: state.identityProvider,
    };
    cookieStorage.set(AUTH_COOKIE_KEY, cookie);
    if (state.refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, state.refreshToken);
    }
  },
  async restoreAuthTokens({ commit, dispatch, getters }, { query }: { query: object }) {
    try {
      const jsonCookie = cookieStorage.get(AUTH_COOKIE_KEY) as AuthCookie;
      if (!jsonCookie) {
        return;
      }
      commit('setLastLogin', {
        lastLoginEmail: jsonCookie.userId,
      });
      const client =
        jsonCookie.clientConfig && jsonCookie.source
          ? new AuthClientBase(jsonCookie.source, jsonCookie.clientConfig as any)
          : null;
      if (!client) {
        // if there is no client, refreshCredentials and initiateLogin can't do anything.
        // prevent the method from rehydrating just the refresh token and implying the user is
        // actually logged in when we have no idea where to go to use that token
        return;
      }
      if (client.source === AUTH_PROVIDER.COGNITO && !jsonCookie.identityProvider) {
        // cognito needs an identity provider to successfully login. trying to log someone
        // in without it will leave them on a weird internal-only page.
        Sentry.captureMessage(
          'Auth session cookie is present without idp. PD session cannot be imported to PEAS!',
          (scope) => {
            scope.setLevel('warning');
            scope.setContext('Cookie', jsonCookie as any);
            return scope;
          },
        );
        return;
      }

      commit('setAuthClient', {
        authClient: client,
        identityProvider: jsonCookie.identityProvider,
      });

      const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
      if (refreshToken) {
        commit('setRefreshToken', { refreshToken });
      }
      try {
        await dispatch('loadUserSession');
      } catch (e) {
        if (refreshToken) {
          try {
            await dispatch('refreshCredentials');
          } catch (err) {
            // do nothing
          }
        }
      }
      if (!getters.isLoggedIn) {
        await dispatch('initiateLogin', { query });
      } else {
        dispatch('identifyUser');
      }
    } catch (e) {
      // error restoring tokens, user is now logged out and should be redirected to /login.
      captureAuthFailure(e as Error, 'restore_session');
      return;
    }
  },
  /**
   * Primes the module to initiate a login with the given login ID by invoking the API to discover
   * the given user's authenticiation provider. Must be called before attempting to authenticate the user.
   * @param payload.idp idp of the user trying to login (same as cid). Used for determine auth client
   * during auto-login flow.
   * @param payload.stagingLoginEmail email of the current user trying to login. Used to determine
   * which auth client to used, based on the auth provider configured for the email domain.
   */
  async stageLoginId(
    { commit },
    { stagingLoginEmail, idp }: { stagingLoginEmail: string; idp: string },
  ) {
    const [, domain] = stagingLoginEmail?.split('@', 2) || [];
    if (!domain && !idp) {
      return;
    }
    const {
      authProvider,
      idp: identityProvider,
      ...clientConfig
    } = (await AuthApi.getLoginConfiguration(domain, idp)) || {};
    commit('setAuthClient', {
      stagingLoginEmail,
      authClient: new AuthClientBase(authProvider, clientConfig as any),
      identityProvider: identityProvider,
    });
  },
  /**
   * Redirects the user that was staged with `stageLoginId` to login via the URL
   * provided by their authentication provider. Does not return.
   * @param param0 Vuex context
   * @param param1.query Parsed querystring object
   */
  async initiateLogin({ state, dispatch }, { query }: { query: object }) {
    await dispatch('trackLoginInit');
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { authorizeUrl, codeVerifier }: any = await state.authClient?.initAuth({
      state: encodeState({
        ...omit(query, ['email', 'idp', 'success', 'message']),
        identityProvider: state.identityProvider,
        lastLoginEmail: state.lastLoginEmail!,
        loginSource: state.authClient.source!,
        clientConfig: state.authClient.getClientConfig() as any,
        // Since Cognito login callback must be a fixed path, we use this in
        // https://console.aws.amazon.com/lambda/home?region=us-east-1#/functions/RemapBranchLoginCallback
        // to figure out the right branch path after login.
        branch: Environment.BRANCH_NAME ? Environment.BRANCH_NAME : undefined,
      }),
      identityProvider: state.identityProvider,
      stagingLoginEmail: state.lastLoginEmail,
    });
    localStorage.setItem('codeVerifier', codeVerifier);
    window.location.replace(authorizeUrl);
    // initAuth causes a redirect - we can return a promise which will never
    // resolve so that any consumers which await this method block infinitely.
    // (this helps to avoid flashing the login screen during auto-login, as well
    // as simplifying the logic for displaying a spinner in the login form)
    return new Promise(noop) as Promise<never>;
  },
  /**
   * Parses the query parameters from an Oauth2 callback to retrieve and store a user's authentication
   * credentials. May fail due to a malformed querystring or other error; consumers should check
   * the `isLoggedIn` getter after dispatching this action to ascertain whether or not login was
   * successful. When login is not successful, an error message will be available via the `lastLoginError` getter.
   */
  async completeLogin(
    { state, commit, dispatch },
    { query }: { query: object & CallbackParameters & { state: string } },
  ) {
    const oauthState = attempt(() => decodeState(query.state));
    if (isError(oauthState)) {
      captureAuthFailure(oauthState, 'decode_state');

      commit('setLoginError', {
        errorMessage:
          'Sorry, we could not sign you in. Please try again and if the error persists, contact support.',
      });
      return;
    }
    commit('setAuthClient', {
      authClient: new AuthClientBase(oauthState.loginSource, oauthState.clientConfig),
      identityProvider: oauthState.identityProvider,
      stagingLoginEmail: oauthState.lastLoginEmail,
    });
    commit('setReturnUrl', {
      queryParameters: omit(oauthState, [
        'branch',
        'loginSource',
        'clientConfig',
        'identityProvider',
        'lastLoginEmail',
      ]),
    });

    try {
      const codeVerifier = localStorage.getItem('codeVerifier');
      const { userInfo, refreshToken } = await state.authClient!.logIn({ query, codeVerifier });
      localStorage.removeItem('codeVerifier');
      commit('setRefreshToken', { refreshToken });
      commit('setUserSession', { userInfo });
      dispatch('trackLogin', { success: true });
      localStorage.setItem(LAST_USER_LOCALSTORAGE_KEY, oauthState.lastLoginEmail);
    } catch (e) {
      if (e instanceof UnreportedLoginError) {
        commit('setLoginError', { errorMessage: e.message });
      } else {
        captureAuthFailure(e as Error, 'complete_login');
        commit('setLoginError', {
          errorMessage:
            'Sorry, we could not sign you in. Please try again and if the error persists, contact support.',
        });
      }
      commit('clearUserSession');
      dispatch('trackLogin', { success: false });
    }
    await dispatch('persistAuthTokens');
  },
  /**
   * Takes in the targetCid (required) and the targetUser (optional) to generate assume role token
   */
  async assumeRole(
    { state, commit, dispatch },
    { targetCid, targetUser }: { targetCid: string; targetUser: string },
  ) {
    try {
      const { userInfo } = await state.authClient!.assumeRole({
        targetCid,
        targetUser,
      });

      commit('setUserSession', { userInfo });
      await dispatch('persistAuthTokens');
    } catch (e) {
      // expected in cases where refresh token is expired or when refresh is unsupported
      commit('clearUserSession');
    }
  },
  /**
   * Reverts assumed user token and sets auth state back to original user
   */
  async revertRole({ state, commit, dispatch }) {
    // invalidate assumed token
    if (!state.refreshToken) {
      commit('clearUserSession');
    }
    try {
      const { userInfo } = await state.authClient!.refreshTokens({
        refreshToken: state.refreshToken!,
      });

      commit('setUserSession', { userInfo });
      await dispatch('persistAuthTokens');
    } catch (e) {
      // expected in cases where refresh token is expired or when refresh is unsupported
      commit('clearUserSession');
    }
  },
  /**
   * Attempts to refresh the user's authentication tokens using the refreshToken returned
   * by the most recent login.
   * @param param0 Vuex context
   */
  async refreshCredentials({ state, commit, dispatch }) {
    if (!state.authClient || !state.refreshToken) {
      throw new Error('Cannot refresh tokens when the user is not logged in');
    }

    try {
      const { userInfo } = await state.authClient.refreshTokens({
        refreshToken: state.refreshToken,
      });

      // check for user perms change
      const oldPermId = state.userInfo?.claims.permId;
      const newPermId = userInfo[CLAIMS_DOMAIN].permId;

      commit('setUserSession', { userInfo });

      // hard refresh page when user permissions have changed
      if (oldPermId && newPermId !== oldPermId) {
        window.location.reload();
        return new Promise(noop) as Promise<never>;
      }

      dispatch('trackRefreshCredentials');
      await dispatch('persistAuthTokens');
    } catch (e) {
      // expected in cases where refresh token is expired or when refresh is unsupported
      commit('clearUserSession');
    }
  },
  async loadUserSession({ commit, getters }) {
    const { userInfo } = await AuthApi.getUserInfo();
    commit('setUserSession', { userInfo });
    localStorage.setItem(LAST_USER_LOCALSTORAGE_KEY, getters.userId);
  },
  async trackLoginInit({ state, dispatch }) {
    await dispatch(
      'analytics/trackEvent',
      {
        event: ANALYTICS_CW_METRICS.SIGNIN_START,
        properties: {
          success: true,
          disableSegment: true,
          authProvider: state.authClient?.source,
        },
      },
      { root: true },
    );
  },
  async trackLogin({ state, dispatch }, { success }) {
    if (success) {
      await dispatch('identifyUser');
      dispatch('analytics/trackEvent', { event: ANALYTICS_EVENT.LOG_IN }, { root: true });
    }
    dispatch(
      'analytics/trackEvent',
      {
        event: ANALYTICS_CW_METRICS.SIGNIN_COMPLETE,
        properties: {
          success,
          disableSegment: true,
          authProvider: state.authClient?.source,
        },
      },
      { root: true },
    );
  },
  async trackRefreshCredentials({ dispatch }) {
    await dispatch('identifyUser');
    dispatch('analytics/trackEvent', { event: ANALYTICS_EVENT.REFRESHED_TOKEN }, { root: true });
  },
  async trackLogout({ dispatch }) {
    await dispatch('identifyUser');
    dispatch('analytics/trackEvent', { event: ANALYTICS_EVENT.LOG_OUT }, { root: true });
  },
  async identifyUser({ dispatch, getters }) {
    const userId = getters['userId'];
    const traits = {
      viewportWidth: $(window).width(),
      viewportHeight: $(window).height(),
    };
    await dispatch(
      'analytics/identifyUser',
      { userId, traits, customerId: getters['customerId'] },
      { root: true },
    );
  },
  async logout({ state, commit, dispatch }) {
    await dispatch('trackLogout');
    const refreshToken = state.refreshToken!;
    commit('clearUserSession');
    cookieStorage.remove(AUTH_COOKIE_KEY);
    dispatch('analytics/clearAnonymousIdCookies', {}, { root: true });
    localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
    await state.authClient?.logOut({ refreshToken });
  },
};

const getters: GetterTree<AuthModuleState, {}> = {
  returnUrl(state) {
    if (!state.returnUrl) {
      return null;
    }
    try {
      const url = new URL(state.returnUrl, window.location.origin);
      url.search = qs.stringify(state.returnQueryParameters);
      return `${url.pathname}${url.search}`;
    } catch {
      // invalid URL - probably someone messing with the query params
      return null;
    }
  },
  lastLoginError(state) {
    return state.loginErrorMessage;
  },
  isLoggedIn(state) {
    return Boolean(state.userInfo);
  },
  isProductivEmployee(state) {
    return state.userInfo?.claims.cid === 'productiv-ai';
  },
  isAssumedRole(state) {
    return Boolean(state.impersonationInfo);
  },
  customerId(state) {
    return state.userInfo?.claims.cid;
  },
  userId(state) {
    return state.userInfo?.email ?? state.lastLoginEmail;
  },
  friendlyName(state) {
    return state.userInfo?.name;
  },
  firstName(state) {
    return state.userInfo?.givenName ?? state.userInfo?.name;
  },
  familyName(state) {
    return state.userInfo?.familyName ?? state.userInfo?.familyName;
  },
  permissions(state, getters, rootState, rootGetters): USER_PERMISSION[] {
    const customerOverride = rootGetters['api/currentCustomerOverride'];
    const cid: string | undefined = customerOverride || state.userInfo?.claims.cid;
    const isProductivEmployee = getters.isProductivEmployee;
    const permissionSets = compact([
      cid && get(state.userPermissions, cid),
      isProductivEmployee && get(state.userPermissions, `${cid}:support`),
      isProductivEmployee && get(state.userPermissions, '*:support'),
    ]);
    // use first defined permission set (in order of priority), ignore the rest
    return head(permissionSets) || [];
  },
  resourcePerms(state, getters, rootState, rootGetters): ResourceStatement[] {
    const customerOverride = rootGetters['api/currentCustomerOverride'];
    const cid: string | undefined = customerOverride || state.userInfo?.claims.cid;
    return state.resourcePermissions?.[cid!] ?? [];
  },
};

export default {
  namespaced: true,
  state: createState,
  mutations,
  actions,
  getters,
} as Module<AuthModuleState, unknown>;
