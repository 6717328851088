export const BASE_URL = import.meta.env.BASE_URL;
export const API_BASE_URL = import.meta.env.VITE_API_URL;
export const BRANCH_NAME = import.meta.env.VITE_BRANCH_NAME || '';
export const BRANCH_OWNER = import.meta.env.VITE_BRANCH_OWNER || '';
export const DASHBOARD_BASE_URL = (() => {
  const branchSuffix = BRANCH_NAME ? `branch/${BRANCH_NAME}/` : '';
  return import.meta.env.VITE_BASE_DOMAIN
    ? `https://app.${import.meta.env.VITE_BASE_DOMAIN}/${branchSuffix}`
    : `https://app.dev.productiv.com/${branchSuffix}`;
})();
export const IS_SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED === 'true' ? true : false;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const COOKIE_DOMAIN = import.meta.env.VITE_BASE_DOMAIN
  ? `.${import.meta.env.VITE_BASE_DOMAIN}`
  : undefined;
export const COOKIE_SUFFIX = import.meta.env.VITE_ENV === 'prod' ? '' : '_dev';
export const IS_ANALYTICS_ENABLED =
  import.meta.env.VITE_ANALYTICS_ENABLED === 'true' ? true : false;
export const IS_API_PROXY_ENABLED = import.meta.env.VITE_API_URL?.includes('api-proxy');
export const ENVIRONMENT_NAME =
  import.meta.env.VITE_ENV === 'prod'
    ? '__PRODUCTION__'
    : import.meta.env.VITE_ENV === 'dev'
      ? '__DEVELOPMENT__'
      : import.meta.env.VITE_ENV === 'local'
        ? '__LOCAL__'
        : '__UNKNOWN__';
export const USER_PILOT_API_KEY = import.meta.env.VITE_USER_PILOT_API_KEY;
