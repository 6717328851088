// Sourced from https://emailregex.com
const EMAIL_REGEX_STRING =
  '(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';
export const IS_EMAIL = new RegExp(`^${EMAIL_REGEX_STRING}$`, 'g');

export const regexValidator = (regex: RegExp, message?: string) => (value: any) => {
  if (!value) {
    return null;
  }
  return !value.toString().match(regex) ? message || 'Not valid format' : null;
};

export const emailValidator = regexValidator(IS_EMAIL, 'Email is not valid');
