import { AxiosInstance } from 'axios';
import { ActionTree, Module } from 'vuex';

export type AppStoreDemoModuleState = {};

export const APP_STORE_DEMO_CID = 'appstoredemo-com';

const actions: ActionTree<AppStoreDemoModuleState, {}> = {
  async resetAppStoreDemoForInternalUser({ rootGetters }) {
    await (rootGetters['api/authenticatedAxiosInstance'] as AxiosInstance)({
      method: 'put',
      url: `appstore/internal/resetMyAppstoreState`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      params: { target_cid: APP_STORE_DEMO_CID },
    });
  },
};

export default {
  namespaced: true,
  state: () => ({}),
  actions,
} as Module<AppStoreDemoModuleState, {}>;
