<template>
  <Component :is="loadingSlot" v-if="showLoadingState" />
  <slot v-else-if="hasErrors" name="error">
    <div class="d-flex flex-wrap align-items-center">
      <div
        v-for="(error, prop) in status.errors"
        :key="prop"
        class="status-indicator error text-warning"
      >
        <BaseIcon name="warning" />
        Error loading data:
        <span class="text-secondary">{{ error.message }}</span>
      </div>
      <button v-if="status.retry" class="ml-2 btn btn-primary retry-button" @click="status.retry">
        Retry
      </button>
    </div>
  </slot>
  <slot v-else />
</template>

<script lang="ts">
import { VNode, computed, defineComponent, PropType, unref, h as createElement } from 'vue';
import { isEmpty, isArray, reduce } from 'lodash';

import { FULLY_COMPATIBLE } from '../../utils/compat';
import BaseIcon from '../BaseIcon.vue';

import StatusProvider from './StatusProvider.vue';
import { useStatusContext, ANONYMOUS_STATUS } from './statusContext';

export default defineComponent({
  name: 'StatusSwitch',
  compatConfig: FULLY_COMPATIBLE,
  components: {
    BaseIcon,
    StatusProvider,
  },
  props: {
    name: {
      type: [String, Symbol, Array] as PropType<string | symbol | (string | symbol)[]>,
      default: ANONYMOUS_STATUS,
    },
  },
  setup(props, { slots }) {
    const names: (string | symbol)[] = isArray(props.name) ? props.name : [props.name];
    const { status, errorHasMultipleOutlets } = useStatusContext(names);
    const hasErrors = computed(() => !isEmpty(status.errors));

    const showLoadingState = computed(
      () => status.loading || (unref(hasErrors) && unref(errorHasMultipleOutlets)),
    );

    const loadingSlot = computed(() => {
      if (!unref(showLoadingState) || !slots.loading) {
        return () => {
          return null;
        };
      }

      return reduce(
        names,
        (contents: () => VNode | VNode[], name) => {
          return () =>
            createElement(StatusProvider, { name, loading: true }, { default: contents });
        },
        slots.loading,
      );
    });

    return {
      status,
      showLoadingState,
      loadingSlot,
      hasErrors,
    };
  },
});
</script>
