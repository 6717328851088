import { COOKIE_SUFFIX } from '@/utils/environment';

export const AUTH_COOKIE_KEY = `authTokens${COOKIE_SUFFIX}`;
export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'refreshToken';
export const LAST_USER_LOCALSTORAGE_KEY = 'lastUser';

export const CLAIMS_DOMAIN = 'https://productiv.ai/v2';
export const TOKEN_TYPE_CLAIM = 'https://productiv.ai/v2/auth';

export enum AUTH_PROVIDER {
  COGNITO = 'cognito',
  AUTH0 = 'auth0',
}

export enum LOGIN_ROUTE_NAMES {
  USER_LOGIN = 'user-login',
  USER_LOGOUT = 'user-logout',
  ASSUME_ROLE = 'assume-role',
  REVERT_ROLE = 'revert-role',
  LOGIN_OAUTH2_CALLBACK = 'login-callback',
}

export const ANALYTICS_CW_METRICS = {
  SIGNIN_START: 'SigninInit',
  SIGNIN_COMPLETE: 'SigninComplete',
};

// Auth token types (mainly types of assume role)
export const AUTH_TOKEN_TYPE = {
  BASIC: 'basic',
  EMPLOYEE_TO_CUSTOMER: 'e2c', // assume customer role but no user impersonation
  EMPLOYEE_TO_USER: 'e2u', // assume customer role with user impersonation
};

export const EMPLOYEE_TO_CUSTOMER_DOMAIN = 'https://productiv.ai/v2/e2c';
export const EMPLOYEE_TO_USER_DOMAIN = 'https://productiv.ai/v2/e2u';

export const EXPECTED_LOGIN_ERRORS = [
  'Could not parse SAML response from Identity Provider',
  'cannot sign up for customer',
  'Account disabled',
  'Invalid email domain for account',
  'SAML Assertion signature is invalid',
  // These errors happen when the customer's SSO integration is not setup correctly
  // and is therefor not sending back complete information.
  'Unable to capture firstName from SAML',
  'Unable to capture lastName from SAML',
  'Unable to capture email from SAML',
  // This error comes from when a user tries to sign in with a disabled account with the Auth0 client
  'user is blocked',
];
