import { createRouter, createWebHistory } from 'vue-router';
import { BASE_URL } from '@/utils/environment';

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes: [],
});

export function useRouter() {
  return router;
}
